export function executeFetchPOST(url = '', data = {}, success: Function, failure?: Function) {
    //let contextElement = document.querySelector('meta[name="contextId"]') as any;
    //const contentValue = contextElement?.content;
    //let headers = new Headers({ 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' });
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Nexus', '7ECE2494-EC15-4CE8-B9F0-767692347C04');
    //if (contentValue) {
    //    headers = new Headers({ 'X-Context-Id': contentValue, 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' });
    //}

    let options = {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: headers,
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    } as any;

    try {
        // Default options are marked with *
        return fetch(url, options)
            .then((response) => {
                if (response.ok) {
                    const parsed = response.json();
                    return parsed;
                }
               
                //else {
                //    if (response.status == 302) {
                //        window.location.href = "/Home/SignOut";
                //    } else {
                //        window.location.href = `/Home/Error`;
                //    }
                //}
            }).then((value) => success(value)).catch((error) => {
                if (failure) {
                    failure(error);
                }
                console.log(error)
                //executePOST("/Home/FrontendError", {
                //    error: JSON.stringify(error),
                //    errorInfo: JSON.stringify({ url: url, options: options })
                //}, () => { });

                //window.location.href = `/Home/Error`;

            }); // parses JSON response into native JavaScript objects

    } catch (error) {
        //executePOST("/Home/FrontendError", {
        //    error: JSON.stringify(error),
        //    errorInfo: JSON.stringify({ url: url, options: options })
        //}, () => { });
        console.log(error)
        //window.location.href = `/Home/Error`;
    }
}



export function executeFetchPOST2(url = '', data = {}, failure?: Function) {
    //let contextElement = document.querySelector('meta[name="contextId"]') as any;
    //const contentValue = contextElement?.content;
    //let headers = new Headers({ 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' });
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Nexus', '7ECE2494-EC15-4CE8-B9F0-767692347C04');
    //if (contentValue) {
    //    headers = new Headers({ 'X-Context-Id': contentValue, 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' });
    //}

    let options = {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: headers,
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    } as any;

    try {
        // Default options are marked with *
        return fetch(url, options)
            .then((response) => {
                if (response.ok) {
                    const parsed = response.json();
                    return parsed;
                }

                //else {
                //    if (response.status == 302) {
                //        window.location.href = "/Home/SignOut";
                //    } else {
                //        window.location.href = `/Home/Error`;
                //    }
                //}
            }).catch((error) => {
                if (failure) {
                    failure(error);
                }
                console.log(error)
                //executePOST("/Home/FrontendError", {
                //    error: JSON.stringify(error),
                //    errorInfo: JSON.stringify({ url: url, options: options })
                //}, () => { });

                //window.location.href = `/Home/Error`;

            }); // parses JSON response into native JavaScript objects

    } catch (error) {
        //executePOST("/Home/FrontendError", {
        //    error: JSON.stringify(error),
        //    errorInfo: JSON.stringify({ url: url, options: options })
        //}, () => { });
        console.log(error)
        //window.location.href = `/Home/Error`;
    }
}


