import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Autocomplete, Card, Chip, CircularProgress, Divider, FormControl, FormHelperText, Paper, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Controller } from 'react-hook-form';
import { PublicFormInputText } from '../common/PublicFormInputText';
import { IStreetAddress } from '../common/common-types';
import Grid from '@mui/material/Unstable_Grid2';
import "./change-address.less";
import { useAddressHandling } from '../common/common-hooks';

export interface IAddressCollector {
    control: any; // Control<IChangeAddress, any>;
    setValue: any;
    title: string;
    namepostalcode: string;
    namecity: string;
    nameaddressDDLValue: string;
    namestaircase: string;
    nameapartment: string;
}

export function AddressCollector(props: IAddressCollector) {

    const { control, title } = props;
    const { addressQuery, debouncedSetter, inputValue, setInputValue } = useAddressHandling();

    useEffect(() => {

        if (addressQuery?.data?.postcodefiname) {
            props.setValue(props.namecity, addressQuery.data.postcodefiname)
        } else if (addressQuery?.data?.postCodeNotFound) {
            props.setValue(props.namecity, null)
        }
        setInputValue('');

    }, [addressQuery.data])
    console.log(`props.namepostalcode`, props.namepostalcode)


    return (<>
        <Card elevation={2} sx={{p: 3, m: 2} }>
            <Grid
                container
                spacing={3}
                key='main-grid-container'
            >
                <Grid xs={12}>
                <Divider>
                    <Chip label={title} size="medium" />
                    </Divider>
                </Grid>
                <Grid xs={12}>
                    <Controller
                        control={control}
                        key={`${props.namepostalcode}-control`}
                        name={props.namepostalcode}
                        render={({ field, fieldState: { error, isTouched } }) => {

                            const { onChange, onBlur, value } = field;
                           
                            return <><FormControl>
                                <TextField
                                    {...field}
                                    required
                                    error={isTouched && !!error}
                                    key={`${props.namepostalcode}-txtfield`}

                                    id={`${props.namepostalcode}-txtfieldid`}
                                   
                                    label="Postinumero"

                                    value={value ?? ''}
                                    onChange={onChange}
                                   
                                    onBlur={(event) => {

                                        debouncedSetter(event.target.value);
                                        onBlur();
                                    }}


                                />
                                <FormHelperText error={false}>Aloita syöttämällä postinumero</FormHelperText>
                            </FormControl>
                                {addressQuery.isLoading && <CircularProgress sx={{ alignSelf: "center", ml: 1 }} color="success" />}
                            </>
                        }}
                    />
                </Grid>



                <Grid xs={12}>
                    <Controller
                        control={control}
                        name={props.namecity}
                        render={({ field }) => {

                            const { onChange, onBlur, value, } = field;

                            return <TextField
                                key={`${props.namecity}-txtfield`}
                                onChange={onChange}
                                onBlur={onBlur}
                                id={`${props.namecity}-txtfield-id`}
                                name={props.namecity}
                                label="Kaupunki"
                                disabled={true}
                                value={addressQuery?.data?.postcodefiname ?? ''}

                            />
                        }} />
                </Grid>
                <Grid xs={12}>
                    <Controller
                        control={control}
                        name={props.nameaddressDDLValue}
                        render={({ field }) => {

                            const { onChange, onBlur, value, } = field;

                            return <Autocomplete
                                disablePortal
                                disabled={false /*noValidPostalCode(postalCodeValue, postalCodeState.invalid)*/}
                                id={`${props.nameaddressDDLValue}-autocomplete`}
                                options={addressQuery?.data?.addresses ?? []}
                                isOptionEqualToValue={(option: IStreetAddress, value: IStreetAddress) => {

                                    if (option && value) {
                                        return option.street == value.street && option.number == value.number;
                                    }

                                    return false;

                                }}

                                autoSelect={true}

                                openText="Avaa"
                                clearText="Tyhjennä"
                                autoHighlight={true}



                                key={`${props.nameaddressDDLValue}-autocomplete`}
                                getOptionLabel={(option) => {

                                    if (option.street && option.number) {
                                        return `${option.street} ${option.number}`
                                    }

                                    return `-`
                                }}
                                value={value ?? null}
                                noOptionsText="Ei osoitteita"

                                onBlur={onBlur}
                                onChange={(event, newValue) => {

                                    onChange(newValue);

                                }}
                                renderInput={(params) => <TextField  {...params} disabled={false} required error={false} label="Osoite" />}

                                inputValue={inputValue}
                                onInputChange={(event, newInputValue, tmp) => {
                                    setInputValue(newInputValue);

                                }}
                            />
                        }}
                    />

                </Grid>
                <Grid xs={12}>
                    <PublicFormInputText
                        key={`${props.namestaircase}-txtfield`}
                        name={props.namestaircase}
                        control={control}
                        label="Rappu"
                        required={true}
                    />
                </Grid>
                <Grid xs={12}>
                    <PublicFormInputText
                        key={`${props.nameapartment}-txtfield`}
                        name={props.nameapartment}
                        control={control}
                        label="Huoneisto"
                        required={true}
                    />
                </Grid>
            </Grid>
        </Card>


    </>);
}