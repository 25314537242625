import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { executeFetchPOST2 } from "./utils";
import { IAddressResponse, IStreetAddress } from "./common-types";
import { debounce } from "@mui/material";

const addressQueryFetch = (postalCode: string) => {

    const payload = {
        postalCode: postalCode
    };

    return executeFetchPOST2(`${process.env.API_URL}/submit/addresses`, payload).then((response: IAddressResponse) => {
        return response;

    })

}

export function useAddressHandling() {

    const [postalCodeState, setPostalCodeState] = useState<{ postalCode: string | null }>({ postalCode: null });
    //const [formState, setFormState] = useState<{ addresses: IStreetAddress[] }>({
    //    addresses: []
    //});
    const [inputValue, setInputValue] = useState('');

    const addressQuery = useQuery({
        queryKey: [postalCodeState.postalCode],
        staleTime: Infinity,

        enabled: postalCodeState.postalCode != '' && postalCodeState.postalCode != null,
        queryFn: ({ queryKey }) => {

            const postalCode = queryKey[0];


            return addressQueryFetch(postalCode).then(response => {
                //if (response.postCodeNotFound) {

                //    //setFormState(state => ({ ...state, addresses: [] }));

                //} else {

                //    //setValue('city', response.postcodefiname);
                //    /*setInputValue('');*/

                //    //setFormState(state => ({ ...state, addresses: response.addresses }));
                //}

                return response;
            });

        },

    })


    const debouncedSetter = useMemo(
        () => debounce((keyword: string) => {


            setPostalCodeState(state => ({ ...state, postalCode: keyword }))
        }, 200)
        , []
    );

    return {
        debouncedSetter,
        //formState,
        inputValue,
        setInputValue,
        addressQuery
    }

}


export interface IStepState {
    activeStep: number;
    message: string | null;
    error: boolean;
}

export function useStepState(trigger: () => void) {
    const [stepState, setStepState] = useState<IStepState>({
        activeStep: 0,
        message: null,
        error: false,
    });


    const handleNext = () => {
        setStepState((prevState) => ({ ...prevState, activeStep: prevState.activeStep + 1 }));
        setTimeout(() => { trigger(); }, 100);  
    };

    const handleBack = () => {
        setStepState((prevState) => ({ ...prevState, activeStep: prevState.activeStep - 1 }));
        setTimeout(() => { trigger(); }, 100);  
    };

    const backToStart = () => {
        setStepState((prevState) => ({ ...prevState, activeStep: 0 }));
    }

    const onSendError = (message: string) => {
        setStepState(state => ({ ...state, error: true, message: message }))
    }

    const onSendSuccess = (message: string) => {
        setStepState(state => ({ ...state, error: false, message: message, activeStep: state.activeStep + 1 }))
    }

    return {
        handleNext,
        handleBack,
        stepState,
        backToStart,
        sendError: stepState.error,
        message: stepState.message,
        onSendSuccess,
        onSendError
    }
}