import { RoleType } from "../meterreading/components/MeterReadingForm";


export enum MeterReadingAllowed {
    Yes = 1,
    No = 2,
    WillReportReadigs = 3
}

export enum ChangeAddressNotificationType {
    In = 1,
    Out = 2
}

export interface IAddressValues {
   
    addressDDLValue: any | null;
    city: string | null;
    postalcode: string | null;
}


export interface IPerson {
    firstName: string | null;
    lastName: string | null;
    dob: any; // Date | null;
}

type PersonWithId = IPerson & { id: string };

export interface IChangeAddress {
    typeOfNotification: ChangeAddressNotificationType | null;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    email: string | null;
    role: RoleType.Resident | RoleType.Tenant | RoleType.Other | null;
    otherName: string | null;
   
    changeOfAddressDate: Date | null;
   
    fromAddress: IAddressValues;
    toAddress: IAddressValues;

    meterReadingAllowed: MeterReadingAllowed | null;
    additionalInfo: string | null;

    persons: PersonWithId [];
    isMoverShareholder: boolean;
    isPropertySold: boolean;
    contractDeliveredEstateManager: boolean;

    acceptTerms: boolean;

}