import "./feedbackform.less";
import * as React from "react";
import { createRoot } from 'react-dom/client';

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/fi';
import { LicenseInfo } from '@mui/x-license-pro';
import { FeedbackForm } from "./FeedbackForm";

export function FeedbackFormApp() {


    return <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale="fi">
        <>
            <div id="main" className="main">

                <div>
                    <><FeedbackForm /></>
                </div>


            </div>
        </>
    </LocalizationProvider>


}
