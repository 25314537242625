import { DevTool } from "@hookform/devtools";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { Alert, Autocomplete, Box, Button, CircularProgress, FormControl, FormHelperText, Grid, LinearProgress, Link, Paper, TextField, Typography, debounce } from "@mui/material";
import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import libphonenumber from 'google-libphonenumber';
import React, { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { bool, number, object, string } from "yup";
import { PublicFormInputCheckbox } from "../common/PublicFormInputCheckbox";
import { PublicFormInputDropdown } from "../common/PublicFormInputDropdown";
import { PublicFormInputText } from "../common/PublicFormInputText";
import { IAddressResponse, IGeneralServerResponse, IStreetAddress } from "../common/common-types";
import { useYupValidationResolver } from "../common/componentUtils";
import { executeFetchPOST2 } from "../common/utils";
import { DetectedFaultType, IPublicServiceRequest, TicketCategoryTypes } from "./public-servicerequest-types";
import "./public-servicerequest.less";
import { RoleType } from "../meterreading/components/MeterReadingForm";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();


type AnyUseQueryOptions = UseQueryOptions<any, unknown>





const addressQueryFetch = (postalCode: string) => {

    const payload = {
        postalCode: postalCode
    };

    return executeFetchPOST2(`${process.env.API_URL}/submit/addresses`, payload).then((response: IAddressResponse) => {
        return response;

    })

}

//export function useQueryResult<T extends AnyUseQueryOptions>(
//    query: T,
//    options?: QueryOptions
//): any {
//    const { data, error, isFetching } = useQuery(query, options)
//    return useMemo(
//        () => queryResult(data, error, isFetching),
//        [data, error, isFetching]
//    )
//}


const validationSchema = object({
    firstName: string().required("Pakollinen"),
    lastName: string().required("Pakollinen"),
    phoneNumber: string().test({
        name: 'is-valid-phonenumber', // Your custom error id
        message: 'Arvon on oltava puhelinnumero',
        test: function () {
            const { phoneNumber } = this.parent; // Access the object data, here this.parent contains your data

            if (phoneNumber) {
                try {
                    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, 'FI');

                    const value = phoneUtil.isValidNumber(number);


                    return value;
                } catch (ex) {
                    return false;
                }
            }
            return false;

        }
    }).required('Arvon on oltava puhelinnumero').typeError('Arvon on oltava puhelinnumero').matches(/^[+]?[0-9 ]*$/, 'Arvon on oltava puhelinnumero'),
    email: string().email('Sähköpostiosoite on virheellinen').required('Pakollinen').typeError('Arvon on oltava sähköposti').nullable(),

    type: number().required('Aihealue on pakollinen'),
    


    additionalInfo: string().required('Pakollinen').nullable(),
    acceptTerms: bool().oneOf([true], 'Sinun on hyväksyttävä tietosuoja- ja rekisteriseloste'),
    postalcode: string().required('Pakollinen').nullable(),
    staircase: string().matches(/^[a-zA-Z ]*$/, 'Arvon on oltava kirjain').typeError('Arvon on oltava kirjain').notRequired().nullable(),

    //apartmentNumber: number().typeError('Arvon on oltava numero').when('meterLocation', {
    //    is: MeterLocation.Apartment,
    //    then: number().typeError('Arvon on oltava numero').required('Asunnon numero on pakollinen'),
    //    otherwise: number().notRequired().nullable()
    //}),
    apartment: string().typeError('Arvo on virheellinen').notRequired().nullable(),

    role: number().required('Rooli on pakollinen').typeError('Rooli on pakollinen'),
    otherName: string().when('role', {
        is: RoleType.Other,
        then: string().required('Kenttä on pakollinen').typeError('Kenttä on on pakollinen'),
        otherwise: string().notRequired().nullable()
    }),
    detectedFault: number().when('type', {
        is: TicketCategoryTypes.HuoltoJaKorjaus,
        then: number().required('Aihealue on pakollinen').typeError('Kenttä on on pakollinen'),
        otherwise: number().notRequired().nullable()
    }),
})

enum TicketFormView {
    Form = 1,
    Send = 2
}


interface IViewState {
    view: TicketFormView
    message: string | null;
    error: boolean;
    errorMessage: string | null
}


function useViewState() {

    const [viewState, setViewState] = useState<IViewState>({

        view: TicketFormView.Form,
        message: null,
        error: false,
        errorMessage: null

    })


    const createNewTicket = () => {
        setViewState(state => ({ ...state, view: TicketFormView.Form, message: null, error: false, errorMessage: null }))
    }

    const onTicketSend = (message: string) => {
        setViewState(state => ({ ...state, view: TicketFormView.Send, message: message, error: false, errorMessage: null }))
    }


    const onSendTicketError = (message: string) => {
        setViewState(state => ({ ...state, message: null, error: true, errorMessage: message }))

    }

    return {
        createNewTicket,
        onTicketSend,
        onSendTicketError,
        message: viewState.message,
        view: viewState.view,
        error: viewState.error,
        errorMessage: viewState.errorMessage
    }

}

export function ServiceRequestForm() {


    const resolver2 = useYupValidationResolver(validationSchema)
    const methods = useForm<IPublicServiceRequest>({
        //@ts-ignore
        defaultValues: { firstName: '', addressDDLValue: null, detectedFault: null, acceptTerms: false, role: null },
        //resolver,
        resolver: async (data, context, options) => {
            // you can debug your validation schema here

            const result = await resolver2(data);
            console.log(`validation:::`);
            console.log(result);

            //if (result.errors.firstName || result.errors.lastName || result.errors.phoneNumber || result.errors.otherName) {
            //    setFlowState(state => ({ ...state, propertyActive: false }))
            //} else {
            //    setFlowState(state => ({ ...state, propertyActive: true }))
            //}


            return await resolver2(data)
        },
        mode: "onTouched"

    })

    const viewState = useViewState();

    const { mutate: sendServiceRequest, isPending: isPendingSend } = useMutation({
        mutationFn: (ticket: IPublicServiceRequest) => {

            return executeFetchPOST2(`${process.env.API_URL}/submit/save-ticket`, ticket).then((response: IGeneralServerResponse) => {

                if (response.success) {
                    viewState.onTicketSend(response.message)

                } else if (!response.success) {
                    viewState.onSendTicketError(response.message)

                }

            });

        },
        onSuccess: () => {

            //return queryClient.invalidateQueries({ queryKey: ['prompts'] })
        },

        // make sure to _return_ the Promise from the query invalidation
        // so that the mutation stays in `pending` state until the refetch is finished
        //onSettled: async () => {

        //    //return await queryClient.invalidateQueries({ queryKey: ['tickets', null] })
        //},
    })


    const {
        register,
        handleSubmit,
        watch,
        getFieldState,
        control,
        formState: { isValid, isDirty, isSubmitSuccessful },
        setValue,
        reset,
        setError,
        getValues,



    } = methods;

    const [postalCodeState, setPostalCodeState] = useState<{ postalCode: string | null }>({ postalCode: null });

   

    const [formState, setFormState] = useState<{ addresses: IStreetAddress[] }>({
        addresses: []
    });

    const [inputValue, setInputValue] = React.useState('');

    const addressQuery = useQuery({
        queryKey: [postalCodeState.postalCode],
        staleTime: Infinity,
       
        enabled: postalCodeState.postalCode != '' && postalCodeState.postalCode != null,
        queryFn: ({ queryKey }) => {

            const postalCode = queryKey[0];

            if (postalCode == null) {
                return Promise.reject(new Error('Tapahtui virhe'))
            }

            return addressQueryFetch(postalCode).then(response => {
                if (response.postCodeNotFound) {

                    setFormState(state => ({ ...state, addresses: [] }));

                } else {

                    //setValue('city', response.postcodefiname);
                    setInputValue('');

                    setFormState(state => ({ ...state, addresses: response.addresses }));
                }

                return response;
            });

        },
        
    })


    const debouncedSetter = useMemo(
        () => debounce((keyword: string) => {


            setPostalCodeState(state => ({ ...state, postalCode: keyword }))
        }, 200)
        , []
    );



    const onSubmit: SubmitHandler<IPublicServiceRequest> = (data) => {
        const withCity = { ...data, city: addressQuery?.data?.postcodefiname ?? '' };
        sendServiceRequest(withCity)
    }


    const selectedRole: RoleType | null = watch('role');
    const selectedType: TicketCategoryTypes | null = watch('type');


    return (
        <div style={{ width: "100%" }}>
            {viewState.view == TicketFormView.Form && <form className="sr-form" onSubmit={handleSubmit(onSubmit)}>

                    <Box sx={{ width: '100%' }}>

                        <Paper sx={{ p: 2 }} elevation={3}>
                            {isPendingSend && <Box sx={{ pb: 1 }}> <LinearProgress /></Box>}
                        <table style={{ width: "100%" }}>

                            <tbody>
                                <tr>
                                    <td>
                                        <PublicFormInputText
                                            key={`firstname-input`}
                                            name="firstName"
                                            control={control}
                                            label="Etunimi"
                                            required={true}
                                        />
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <PublicFormInputText
                                            name="lastName"
                                            control={control}
                                            label="Sukunimi"
                                            required={true}
                                        />
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Controller
                                            control={control}
                                            name="postalcode"
                                            render={({ field, fieldState: { error } }) => {

                                                const { onChange, onBlur, value } = field;

                                                return <><FormControl>
                                                    <TextField

                                                        required
                                                        error={!!error}
                                                        key="postalcode-txtfield"

                                                        id="postalcode-txtfield"
                                                        name="postalcode"
                                                        label="Postinumero"

                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                        onBlur={(event) => {

                                                            debouncedSetter(event.target.value);
                                                            onBlur();
                                                        }}


                                                    />
                                                    <FormHelperText error={false}>Aloita syöttämällä postinumero</FormHelperText>
                                                </FormControl>
                                                    {addressQuery.isLoading && <CircularProgress sx={{ alignSelf: "center", ml: 1 }} color="success" />}
                                                </>
                                            }}
                                        />

                                    </td>
                                    <td></td>


                                </tr>
                                <tr>
                                    <td>
                                        <Controller
                                            control={control}
                                            name="city"
                                            render={({ field }) => {

                                                const { onChange, onBlur, value, } = field;

                                                return <TextField
                                                    key="city-textfield"

                                                    id="city-txtfield"
                                                    name="city"
                                                    label="Kaupunki"
                                                    disabled={true}
                                                    value={addressQuery?.data?.postcodefiname ?? ''}

                                                />
                                            }} />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <Controller
                                            control={control}
                                            name="addressDDLValue"
                                            render={({ field }) => {

                                                const { onChange, onBlur, value, } = field;

                                                return <Autocomplete
                                                    disablePortal
                                                    disabled={false /*noValidPostalCode(postalCodeValue, postalCodeState.invalid)*/}
                                                    id="addressDDLValue-component"
                                                    options={formState.addresses}
                                                    isOptionEqualToValue={(option: IStreetAddress, value: IStreetAddress) => {

                                                        if (option && value) {
                                                            return option.street == value.street && option.number == value.number;
                                                        }

                                                        return false;

                                                    }}

                                                    autoSelect={true}

                                                    openText="Avaa"
                                                    clearText="Tyhjennä"
                                                    autoHighlight={true}



                                                    key="addresse-autocomplete"
                                                    getOptionLabel={(option) => {

                                                        if (option.street && option.number) {
                                                            return `${option.street} ${option.number}`
                                                        }

                                                        return `-`
                                                    }}
                                                    value={value ?? null}
                                                    noOptionsText="Ei osoitteita"

                                                    onBlur={onBlur}
                                                    onChange={(event, newValue) => {

                                                        onChange(newValue);

                                                    }}
                                                    renderInput={(params) => <TextField  {...params} disabled={false} required error={false} label="Osoite" />}

                                                    inputValue={inputValue}
                                                    onInputChange={(event, newInputValue, tmp) => {
                                                        setInputValue(newInputValue);

                                                    }}
                                                />
                                            }}
                                        />
                                    </td>
                                    <td></td>

                                </tr>
                                <tr>
                                    <td>
                                        <PublicFormInputText name="staircase" control={control} label="Rappu" />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <PublicFormInputText name="apartment" control={control} label="Huoneisto" />
                                    </td>
                                    <td></td>
                                </tr>

                                <tr>

                                    <td>
                                        <PublicFormInputText
                                            name="phoneNumber"
                                            control={control}
                                            label="Puhelinnumero"
                                            required={true}
                                        />
                                    </td>

                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <PublicFormInputText
                                            name="email"
                                            control={control}
                                            label="Sähköposti"
                                            required={true}
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <PublicFormInputDropdown
                                            name="role"
                                            required={true}
                                            control={control}
                                            label="Ilmoittajan rooli"
                                            options={[
                                                { value: RoleType.Resident, label: "Osakas" },
                                                { value: RoleType.Tenant, label: "Vuokralainen" },
                                                { value: RoleType.PropertyMaintenance, label: "Huoltoyhtiö" },
                                                { value: RoleType.Other, label: "Muu" },

                                            ]}
                                        />
                                    </td>
                                    <td>

                                    </td>

                                </tr>
                                {selectedRole == RoleType.Other && <tr>
                                    <td>
                                        <PublicFormInputText
                                            name="otherName"
                                            control={control}
                                            label="Muu, mikä?"
                                            required={true}
                                        />
                                    </td>
                                    <td></td>
                                </tr>}

                                <tr>
                                    <td>
                                        <PublicFormInputDropdown
                                            name="type"
                                            required={true}
                                            control={control}
                                            label="Huoltopyynnön tyyppi"
                                            options={[
                                                { value: TicketCategoryTypes.HuoltoJaKorjaus, label: "Huolto ja korjaus" },
                                                { value: TicketCategoryTypes.Siivous, label: "Siivous" },
                                                { value: TicketCategoryTypes.Ulkoalueidenhoito, label: "Ulkoalueiden hoito" },
                                                

                                            ]}
                                        />
                                    </td>
                                    <td></td>
                                </tr>


                                {selectedType == TicketCategoryTypes.HuoltoJaKorjaus && <tr>
                                    <td>
                                        <PublicFormInputDropdown
                                            name="detectedFault"
                                            required={true}
                                            control={control}
                                            label="Havaittu vika"
                                            options={[
                                                { value: DetectedFaultType.WaterAndPipes, label: "Vesi tai viemäröinti" },
                                                { value: DetectedFaultType.Heating, label: "Lämmitys" },
                                                { value: DetectedFaultType.Electricity, label: "Sähkö" },
                                                { value: DetectedFaultType.Other, label: "Muu korjaustarve" },

                                            ]}
                                        />
                                    </td>
                                    <td></td>

                                </tr>}
                                <tr>
                                    <td>
                                        <PublicFormInputText
                                            name="additionalInfo"
                                            control={control}
                                            label="Lisätiedot"
                                            required={true}
                                            multiline={true} />

                                    </td>
                                    <td></td>

                                </tr>
                                <tr>

                                    <td>
                                        <PublicFormInputCheckbox
                                            control={control}
                                            name="acceptTerms"
                                            required={true}
                                            label={<span>
                                                <span>Vakuutan tiedot oikeiksi ja hyväksyn </span>
                                                <Link href={'https://uniko.fi/tietosuojaseloste'} target="_blank">tietosuoja- ja rekisteriselosteen</Link>

                                            </span>}
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <Button
                                            disabled={!methods.formState.isValid || isPendingSend}
                                            color="success" variant="contained"
                                            type="submit">
                                            Lähetä
                                        </Button>
                                    </td>
                                    <td>
                                       
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <DevTool control={control} />
                    </Paper>
                </Box>
            </form>}
            {viewState.view == TicketFormView.Send && <ValueSendMessage
                createNewTicket={() => {
                    setPostalCodeState(state => ({ ...state, postalCode: null }))
                    methods.reset();
                    viewState.createNewTicket();
                }}
                message={viewState.message} />}
            {viewState.error && <SendErrorNotification message={viewState.errorMessage} />}
        </div>

    )
}

export interface ISendErrorNotification {
    message: string | null;
}

export function SendErrorNotification(props: ISendErrorNotification) {
    return (
        <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error">
            {props.message}
        </Alert>

    );
}


interface IValueSendMessageProps {
    createNewTicket: () => void;
    message: string | null;
}

function ValueSendMessage(props: IValueSendMessageProps) {
    return (<>
        <Paper sx={{ p: 2, position: "relative", height: "100%", minHeight: 100 }} elevation={3}>

            <Grid container spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid xs={1}>
                    <CheckCircleOutlineIcon fontSize="large" color="success" />
                </Grid>

                <Grid xs={11}>
                    <Typography sx={{ ml: 1 }}>
                        {props.message}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid xs={1}>

                </Grid>

                <Grid xs={11}>
                    <Button
                        color="inherit"
                        variant="contained"
                        onClick={(event) => {
                            props.createNewTicket();
                        }}
                        sx={{ mt: 3 }}
                    >
                        Lisää uusi vikailmoitus
                    </Button>
                </Grid>
            </Grid>




        </Paper>
    </>)
}

