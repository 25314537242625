import { Alert, AlertTitle, Autocomplete, Button, Checkbox, CircularProgress, Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, debounce, Paper, Typography, Backdrop, Stepper, StepLabel, Step, Link } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { number, string, object, bool, ValidationError } from "yup";
import { DatePicker } from "@mui/x-date-pickers";
import libphonenumber from 'google-libphonenumber';
import _ from "lodash";
import Grid from '@mui/material/Unstable_Grid2';
import { AnyMessageParams } from "yup/lib/types";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { executeFetchPOST } from "../../common/utils";
import { IStreetAddress } from "../../common/common-types";
export enum NotificationType {
    Success = 1,
    Error = 2
}
export enum RoleType {
    Resident = 1,
    PropertyMaintenance = 2,
    Other = 3,
    Tenant = 4
}

export enum MeterType {
    GenericWater = 1,
    GenericElectricity = 2,
    GenericDistrictHeating = 3,

    FivaldiColdWater = 21, //kylmä vesi
    FivaldiHotWater = 22, //lämmin vesi
    FivaldiApartmentElectricity = 23, //asuntokohtainen sähkö
    FivaldiOverallElectricity = 24, //kokonaissähkö
    FivaldiRemoteHeating = 25, //kaukolämpö
    FivaldiCarChargingPoint = 26, //auton latauspiste

    FivaldiDayElectricity = 27, //päiväsähkö
    FivaldiNightElectricity = 28, //yösähkö
    FivaldiWinterElectricity = 29, //talvisähkö,

    FivaldiWater = 30, //Ylätason fivaldimittarien luokittelut
    FivaldiElectricity = 31,
    FivaldiDistrictHeating = 32,

    Undefined = 99
}

export enum MeterLocation {
    Apartment = 1,
    Property = 2
}

export enum ElectricityMeterType {
    Apartment = 1,
    CarCharger = 2,
    FivaldiElectricityMeter = 3,
    FivaldiCarChargingPointMeter = 4
}

export enum WaterMeterType {
    Cold = 1,
    Warm = 2,
    NotSure = 3,

    FivaldiMeter = 6 //mitttariksi valittu oikea fivaldimittari

}

export interface IMeterState {
    meters: IMeterModel[];
    fetchSuccess: boolean;
}

export interface IMeterModel {
    id: string;
    meterType: MeterType;
    identifier: string;
    propertyDisplayName: string;
    name: string;
}


const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();


const validationSchema = object().shape({
    lastName: string().required('Sukunimi on pakollinen').typeError('Sukunimi on pakollinen'),
    firstName: string().required('Etunimi on pakollinen').typeError('Etunimi on pakollinen'),
    phoneNumber: string().test({
        name: 'is-valid-phonenumber', // Your custom error id
        message: 'Arvon on oltava puhelinnumero',
        test: function () {
            const { phoneNumber } = this.parent; // Access the object data, here this.parent contains your data

            if (phoneNumber) {
                try {
                    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, 'FI');

                    const value = phoneUtil.isValidNumber(number);


                    return value;
                } catch (ex) {
                    return false;
                }
            }
            return false;

        }
    }).required('Arvon on oltava puhelinnumero').typeError('Arvon on oltava puhelinnumero').matches(/^[+]?[0-9 ]*$/, 'Arvon on oltava puhelinnumero'),

    email: string().email('Sähköpostiosoite on virheellinen').notRequired().typeError('Arvon on oltava sähköposti').nullable(),
    housingCompany: string().notRequired().nullable(),


    role: number().required('Rooli on pakollinen').typeError('Rooli on pakollinen'),
    otherName: string().when('role', {
        is: RoleType.Other,
        then: string().required('Kenttä on pakollinen').typeError('Kenttä on on pakollinen'),
        otherwise: string().notRequired().nullable()
    }),
    address: object().required('Osoite on pakollinen'),

    staircase: string().matches(/^[a-zA-Z ]*$/, 'Arvon on oltava kirjain').typeError('Arvon on oltava kirjain').notRequired().nullable(),

    //apartmentNumber: number().typeError('Arvon on oltava numero').when('meterLocation', {
    //    is: MeterLocation.Apartment,
    //    then: number().typeError('Arvon on oltava numero').required('Asunnon numero on pakollinen'),
    //    otherwise: number().notRequired().nullable()
    //}),
    apartment: string().typeError('Arvo on virheellinen').notRequired().nullable(),

    additionalInfo: string().notRequired().nullable(),
    postalcode: string().required('Postinumero on pakollinen').typeError('Postinumero on pakollinen'),
    city: string().typeError('Kaupunki on pakollinen').required('Kaupunki on pakollinen'),
    meterType: number().required('Mittarityyppi on pakollinen').typeError('Mittarityyppi on pakollinen'),
    waterMeterType: number().when('meterType', {
        is: MeterType.GenericWater,
        then: number().required('Vesimittarityyppi on pakollinen').typeError('Vesimittarityyppi on pakollinen'),
        otherwise: number().notRequired().nullable()
    }),
    electricityMeterType: number().when('meterType', {
        is: MeterType.GenericElectricity,
        then: number().required('Sähkömittarityyppi on pakollinen').typeError('Sähkömittarityyppi on pakollinen'),
        otherwise: number().notRequired().nullable()
    }),

    carSpaceNumber: number().when(['electricityMeterType', 'meterType'], {
        is: (electricityMeterType, meterType) => electricityMeterType == ElectricityMeterType.CarCharger && meterType == MeterType.GenericElectricity,
        then: number().typeError('Arvon on oltava numero').required('Autopaikan numero on pakollinen'),
        otherwise: number().typeError('Arvon on oltava numero').notRequired().nullable()
    }),
    meterLocation: number().required('Mittarin sijainti on pakollinen'),
    acceptTerms: bool().oneOf([true], 'Sinun on hyväksyttävä tietosuoja- ja rekisteriseloste'),

    readingDate: string().required('Luentapäivämäärä on pakollinen').typeError('Luentapäivämäärä on pakollinen'),
    meterReading: number().typeError('Arvon on oltava numero').required('Arvon on oltava numero')
        .transform((_, value) => {

            if (!value) return null;

            if (value?.includes('.')) {
                return null;
            }
            return +value.replace(/,/, '.');
        })
        .positive('Arvon tulee olla positiivinen numero')


}).test(
    'apartment or staircase',
    'Toimiiko testi',
    (value, context) => {

        if (value.meterLocation == MeterLocation.Apartment) {

            //const returnValue = _.has(value, 'apartmentNumber') || _.has(value, 'staircase');
            if (value.apartment || value.staircase?.length > 0) {

                return true;
            }

            /*context.createError({ path: 'staircase', message: 'Arvo on pakollinen22222'})*/
            const error1 = context.createError({ path: "staircase", message: 'Huoneisto tai rappu annettava' });
            const error2 = context.createError({ path: "apartment", message: 'Huoneisto tai rappu annettava' });
            // return error1;
            return new ValidationError([error1, error2]);
        }

        return true;
    }
);



const steps = ['Ilmoittajan tiedot', 'Kohteen tiedot', 'Mittaritiedot'];

interface IFlowState {
    propertyActive: boolean;
    meterActive: boolean;
}

export function MeterReadingForm() {

    const [stepState, setStepState] = useState<{ activeStep: number }>({
        activeStep: 0
    });


    const handleNext = () => {
        setStepState((prevState) => ({ ...prevState, activeStep: prevState.activeStep + 1 }));
    };

    const handleBack = () => {
        setStepState((prevState) => ({ ...prevState, activeStep: prevState.activeStep - 1 }));
    };


    const formik = useFormik({
        initialValues: {
            firstName: null,
            lastName: null,
            phoneNumber: null,
            email: null,
            housingCompany: null,
            role: null, //RoleType.Resident,
            otherName: null,
            address: null,
            staircase: null,
            apartment: null,
            additionalInfo: null,
            postalcode: null,
            city: null,
            meterLocation: MeterLocation.Apartment,
            meterType: null,
            waterMeterType: null,
            electricityMeterType: null,
            meterReading: null,
            carSpaceNumber: null,
            acceptTerms: false,
            readingDate: null

        },

        validationSchema: validationSchema,
        onSubmit: (values, helpers) => {


            //convert meterReading value commans to dots
            let correctedMeterReadingValue = values.meterReading;
            if (correctedMeterReadingValue?.includes(',')) {
                correctedMeterReadingValue = correctedMeterReadingValue.replace(/,/, '.');
            }

            const payload = {
                ...values,
                meterReading: correctedMeterReadingValue,
                // apartmentNumber: values.meterLocation == MeterLocation.Apartment ? values.apartmentNumber : null,
            }

            executeFetchPOST(`${process.env.API_URL}/submit/savemeterreading`, payload, (response: { success: boolean }) => {

                if (response.success) {
                    setTimeout(() => { helpers.resetForm() }, 100);
                    setFilterKeyword(state => ({ ...state, postalCode: '' }));
                    setStepState((state) => ({ ...state, activeStep: 3 }));
                    setNotificationState(state => ({ showNotification: true, message: `Lukema ${payload.meterReading} lähetetty onnistuneesti`, notificationType: NotificationType.Success }));

                } else if (!response.success) {
                    //show error message
                    setNotificationState(state => ({ showNotification: true, message: 'Lukeman lähettäminen epäonnistui, tarkista lomakkeen arvot', notificationType: NotificationType.Error }));
                }



            });

        },
    });
   
    const [notificationState, setNotificationState] = useState<{ showNotification: boolean, message: string, notificationType: NotificationType }>({
        showNotification: false,
        message: '',
        notificationType: null
    });


    const [filterKeyword, setFilterKeyword] = useState<{ postalCode: string }>({
        postalCode: ''
    });

    const [formState, setFormState] = useState<{ addresses: IStreetAddress[] }>({
        addresses: []
    });

    const [loadingState, setLoadingState] = useState<{ isLoadingAddresses: boolean, isLoadingMeters: boolean }>({
        isLoadingAddresses: false,
        isLoadingMeters: false
    });

    const [inputValue, setInputValue] = React.useState('');

    const debouncedSetter = useMemo(
        () => debounce((keyword: string) => setFilterKeyword(state => ({ ...state, postalCode: keyword })), 200), []
    );

    const [flowState, setFlowState] = useState<IFlowState>({
        propertyActive: false,
        meterActive: false
    });

    const noValidPostalCode = (postalcode: string, postalcodeErrors: any) => {
        if (postalcodeErrors || !postalcode) {
            return true;
        }
        return false;
    }

    const [meterState, setMeterState] = useState<IMeterState>({
        meters: [],
        fetchSuccess: false
    });


    useEffect(() => {
        if (!formik.errors.firstName && !formik.errors.lastName && !formik.errors.phoneNumber && formik.values.firstName && formik.values.lastName && formik.values.phoneNumber && formik.values.role != null && flowState.propertyActive == false) {
            setFlowState(state => ({ ...state, propertyActive: true }))
        } else if ((formik.errors.firstName || formik.errors.lastName || formik.errors.phoneNumber || !formik.values.firstName || !formik.values.lastName || !formik.values.phoneNumber) && flowState.propertyActive == true) {
            setFlowState(state => ({ ...state, propertyActive: false }))
        }

        if (!formik.errors.postalcode && !formik.errors.address && formik.values.postalcode && formik.values.address && flowState.meterActive == false) {
            setFlowState(state => ({ ...state, meterActive: true }))
        } else if ((formik.errors.postalcode || formik.errors.address || !formik.values.postalcode || !formik.values.address) && flowState.meterActive == true) {
            setFlowState(state => ({ ...state, meterActive: false }))
        }


    }, [formik.values, formik.errors]);

    const setAllFieldsTouched = (formik) => {

        setTimeout(() => {
            formik.setTouched({
                firstName: true,
                lastName: true,
                phoneNumber: true,
                email: true,
                housingCompany: true,
                role: true,
                otherName: true,
                address: true,
                staircase: true,
                apartment: true,
                additionalInfo: true,
                postalcode: true,
                city: true,
                meterLocation: true,
                meterType: true,
                waterMeterType: true,
                electricityMeterType: true,
                meterReading: true,
                carSpaceNumber: true,
                acceptTerms: true,

            }, true);
        }, 100);
    }

    useEffect(() => {


        if (filterKeyword.postalCode === '') return;

        const payload = {
            postalCode: filterKeyword.postalCode
        };
        setLoadingState(state => ({ ...state, isLoadingAddresses: true }));

        executeFetchPOST(`${process.env.API_URL}/submit/addresses`, payload, (response: { addresses: IStreetAddress[], postcodefiname: string, postCodeNotFound: boolean }) => {

            setLoadingState(state => ({ ...state, isLoadingAddresses: false }));

            if (response.postCodeNotFound) {


                formik.setValues(valus => ({ ...valus, address: '', city: '' }));
                setFormState(state => ({ ...state, addresses: [] }));

                setTimeout(() => {
                    formik.setFieldError('postalcode', 'Postinumeroa ei löytynyt');
                }, 200);

            } else {

                const addressesString = response.addresses.map((address) => `${address.street} ${address.number}`);
                formik.setValues(valus => ({ ...valus, address: '', city: response.postcodefiname }));
                setInputValue('');

                setFormState(state => ({ ...state, addresses: response.addresses }));
            }

        });

    }, [filterKeyword.postalCode])

    useEffect(() => {

        if (stepState.activeStep == 2) {

            const payload = {
                address: formik.values.address,
                staircase: formik.values.staircase,
                apartment: formik.values.apartment,
                meterLocation: formik.values.meterLocation
            };
            setLoadingState(state => ({ ...state, isLoadingMeters: true }));

            executeFetchPOST(`${process.env.API_URL}/submit/get-meters`, payload, (response: { success: boolean, meters: IMeterModel[] }) => {


                setMeterState(state => ({ ...state, fetchSuccess: response.success, meters: response.meters }));
                setLoadingState(state => ({ ...state, isLoadingMeters: false }));
                //setLoadingState(state => ({ ...state, isLoadingAddresses: false }));

                //if (response.postCodeNotFound) {


                //    formik.setValues(valus => ({ ...valus, address: '', city: '' }));
                //    setFormState(state => ({ ...state, addresses: [] }));

                //    setTimeout(() => {
                //        formik.setFieldError('postalcode', 'Postinumeroa ei löytynyt');
                //    }, 200);

                //} else {

                //    const addressesString = response.addresses.map((address) => `${address.street} ${address.number}`);
                //    formik.setValues(valus => ({ ...valus, address: '', city: response.postcodefiname }));
                //    setInputValue('');

                //    setFormState(state => ({ ...state, addresses: response.addresses }));
                //}

            });
        }



    }, [stepState.activeStep, formik.values.apartment, formik.values.staircase, formik.values.address, formik.values.meterLocation])


    const isLoading = loadingState.isLoadingAddresses || loadingState.isLoadingMeters;

    return (

        <div style={{ width: "100%" }}>

            <form onSubmit={(event) => {
                //set all fields touched

                setAllFieldsTouched(formik);



                /* formik.setTouched({ name: true, role: true, address: true, postalcode: true, city: true, meterReading: true });*/
                formik.handleSubmit(event)
            }}>

                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={stepState.activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <>
                        <Box sx={{ mt: 2 }}></Box>
                        {stepState.activeStep == 0 && <PersonInformation
                            formik={formik}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            stepState={stepState}
                            flowState={flowState}
                            setAllFieldsTouched={setAllFieldsTouched} />}

                        {stepState.activeStep == 1 && <PropertyInformation
                            formik={formik}
                            debouncedSetter={debouncedSetter}
                            flowState={flowState}
                            formState={formState}
                            inputValue={inputValue}
                            isLoading={isLoading}
                            noValidPostalCode={noValidPostalCode}
                            setInputValue={setInputValue}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            stepState={stepState}
                            setAllFieldsTouched={setAllFieldsTouched}
                        />}

                        {stepState.activeStep == 2 && <MeterInformation
                            meterState={meterState}
                            isLoading={isLoading}
                            flowState={flowState}
                            setAllFieldsTouched={setAllFieldsTouched}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            notificationState={notificationState}
                            setNotificationState={setNotificationState}
                            stepState={stepState}
                            formik={formik} />}

                        {stepState.activeStep == 3 && <ValueSendMessage setStepState={setStepState} />}

                    </>

                </Box>

            </form>
            {false && <NotificationDisplay
                showNotification={notificationState.showNotification}
                notificationType={notificationState.notificationType}
                message={notificationState.message}
                onClose={() => { setNotificationState(state => ({ ...state, showNotification: false })) }} />}
        </div>)
}


export function NotificationDisplay(props: { showNotification: boolean, message: string, notificationType: NotificationType, onClose: () => void }) {

    if (props.showNotification) {

        //if (props.notificationType == NotificationType.Success) {
        //    return <>
        //        <Alert severity="success" onClose={() => { props.onClose() }}>
        //            <AlertTitle>Valmis</AlertTitle>
        //            {props.message}
        //        </Alert>

        //    </>
        //} else

        if (props.notificationType == NotificationType.Error) {
            return <>
                <tr>
                    <td>
                        <Alert severity="error" onClose={() => { props.onClose() }}>
                            <AlertTitle>Virhe</AlertTitle>
                            {props.message}
                        </Alert>
                    </td>
                    <td>
                    </td>
                </tr>
            </>
        }
    }

    return <></>

}

export interface IWaterMeterReadingProps {
    formik: any;
    meterActive: boolean;
    meterState: IMeterState;
}

export function WaterMeterTypePicker(props: IWaterMeterReadingProps) {

    if (props.formik.values.meterType == MeterType.GenericWater) {
        return <>
            <tr>

                <td>
                    <FormControl fullWidth>
                        <InputLabel disabled={!props.meterActive} required id="water-meter-type-label">Vesimittari </InputLabel>
                        <Select
                            disabled={!props.meterActive}
                            labelId="water-meter-type-label"
                            id="waterMeterType"
                            name="waterMeterType"
                            value={props.formik.values.waterMeterType || ''}
                            label="Vesimittarin tyyppi"
                            error={props.formik.touched.waterMeterType && Boolean(props.formik.errors.waterMeterType)}
                            onBlur={props.formik.handleBlur}
                            onChange={props.formik.handleChange}>
                            <MenuItem value={WaterMeterType.Cold}>Kylmä vesi</MenuItem>
                            <MenuItem value={WaterMeterType.Warm}>Lämmin vesi</MenuItem>
                            <MenuItem value={WaterMeterType.NotSure}>En ole varma</MenuItem>

                        </Select>
                        {props.formik.touched.waterMeterType && Boolean(props.formik.errors.waterMeterType) && <FormHelperText error={true}>{props.formik.errors.waterMeterType}</FormHelperText>}
                    </FormControl>

                </td>
                <td></td>
            </tr>
        </>
    } else {

        const fivaldiColdWaterMeters = props.meterState.meters?.filter(m => m.meterType == MeterType.FivaldiColdWater);
        const fivaldiHotWaterMeters = props.meterState.meters?.filter(m => m.meterType == MeterType.FivaldiHotWater);



        return <>
            <tr>

                <td>
                    <FormControl fullWidth>
                        <InputLabel disabled={!props.meterActive} required id="water-meter-type-label">Vesimittari </InputLabel>
                        <Select
                            disabled={!props.meterActive}
                            labelId="water-meter-type-label"
                            id="waterMeterType"
                            name="waterMeterType"
                            value={props.formik.values.fivaldiMeterId || ''}
                            label="Vesimittarin tyyppi"
                            error={props.formik.touched.waterMeterType && Boolean(props.formik.errors.waterMeterType)}
                            onBlur={props.formik.handleBlur}
                            onChange={(event) => {

                                props.formik.setValues(val => ({ ...val, fivaldiMeterId: event.target.value, waterMeterType: WaterMeterType.FivaldiMeter }));

                            }}>
                            {fivaldiColdWaterMeters?.map(m => <MenuItem value={m.id}>{`${m.name}`}</MenuItem>)}
                            {fivaldiHotWaterMeters?.map(m => <MenuItem value={m.id}>{`${m.name}`}</MenuItem>)}


                        </Select>
                        {props.formik.touched.waterMeterType && Boolean(props.formik.errors.waterMeterType) && <FormHelperText error={true}>{props.formik.errors.waterMeterType}</FormHelperText>}
                    </FormControl>

                </td>
                <td></td>
            </tr>
        </>
    }
}

export interface IElectricityMeterTypePickerProps {
    formik: any;
    meterActive: boolean;
    meterState: IMeterState;
}
export function ElectricityMeterFields(props: IElectricityMeterTypePickerProps) {

    if (props.formik.values.meterType == MeterType.GenericElectricity) {
        return <>
            <tr>

                <td>
                    <FormControl fullWidth>
                        <InputLabel disabled={!props.meterActive} required id="electricity-meter-type-label">Sähkömittari </InputLabel>
                        <Select
                            disabled={!props.meterActive}
                            labelId="electricity-meter-type-label"
                            id="electricityMeterType"
                            name="electricityMeterType"
                            value={props.formik.values.electricityMeterType || ''}
                            onChange={props.formik.handleChange}

                            onBlur={props.formik.handleBlur}
                            error={props.formik.touched.electricityMeterType && Boolean(props.formik.errors.electricityMeterType)}
                            label="Sähkömittarin tyyppi"
                        >
                            <MenuItem value={ElectricityMeterType.Apartment}>Asuntokohtainen sähkö</MenuItem>
                            <MenuItem value={ElectricityMeterType.CarCharger}>Auton latauspiste</MenuItem>


                        </Select>
                        {props.formik.touched.electricityMeterType && Boolean(props.formik.errors.electricityMeterType) && <FormHelperText error={true}>{props.formik.errors.electricityMeterType}</FormHelperText>}
                    </FormControl>

                </td>
                <td></td>
            </tr>
            {props.formik.values.electricityMeterType == ElectricityMeterType.CarCharger && <tr>


                <td>
                    <TextField
                        disabled={!props.meterActive}
                        required
                        id="car-space-number-txtfield"
                        name="carSpaceNumber"
                        label="Autopaikan numero"

                        value={props.formik.values.carSpaceNumber || ''}
                        onChange={props.formik.handleChange}

                        onBlur={props.formik.handleBlur}
                        error={props.formik.touched.carSpaceNumber && Boolean(props.formik.errors.carSpaceNumber)}
                        helperText={props.formik.touched.carSpaceNumber && props.formik.errors.carSpaceNumber}
                    />
                </td>

                <td></td>
            </tr>}
        </>
    } else {

        const fivaldiMeters = props.meterState.meters?.filter(m => m.meterType == MeterType.FivaldiApartmentElectricity ||
            m.meterType == MeterType.FivaldiDayElectricity ||
            m.meterType == MeterType.FivaldiElectricity ||
            m.meterType == MeterType.FivaldiNightElectricity ||
            m.meterType == MeterType.FivaldiOverallElectricity ||
            m.meterType == MeterType.FivaldiWinterElectricity)



        return <>

            <tr>

                <td>
                    <FormControl fullWidth>
                        <InputLabel disabled={!props.meterActive} required id="electricity-meter-type-label">Sähkömittari </InputLabel>
                        <Select
                            disabled={!props.meterActive}
                            labelId="electricity-meter-type-label"
                            id="electricityMeterType"
                            name="electricityMeterType"
                            value={props.formik.values.fivaldiMeterId || ''}
                            onChange={(event) => {

                                const selectedMeter = props.meterState.meters.find(m => m.id == event.target.value)

                                props.formik.setValues(val => ({
                                    ...val,
                                    fivaldiMeterId: event.target.value,
                                    electricityMeterType: selectedMeter.meterType == MeterType.FivaldiCarChargingPoint ? ElectricityMeterType.FivaldiCarChargingPointMeter : ElectricityMeterType.FivaldiElectricityMeter
                                }));

                            }}
                            onBlur={props.formik.handleBlur}
                            error={props.formik.touched.electricityMeterType && Boolean(props.formik.errors.electricityMeterType)}
                            label="Sähkömittarin tyyppi"
                        >
                            {fivaldiMeters?.map(m => <MenuItem value={m.id}>{`${m.name}`}</MenuItem>)}



                        </Select>
                        {props.formik.touched.electricityMeterType && Boolean(props.formik.errors.electricityMeterType) && <FormHelperText error={true}>{props.formik.errors.electricityMeterType}</FormHelperText>}
                    </FormControl>

                </td>
                <td></td>
            </tr>
            {props.formik.values.electricityMeterType == ElectricityMeterType.FivaldiCarChargingPointMeter && <tr>


                <td>
                    <TextField
                        disabled={!props.meterActive}
                        required
                        id="car-space-number-txtfield"
                        name="carSpaceNumber"
                        label="Autopaikan numero"

                        value={props.formik.values.carSpaceNumber || ''}
                        onChange={props.formik.handleChange}

                        onBlur={props.formik.handleBlur}
                        error={props.formik.touched.carSpaceNumber && Boolean(props.formik.errors.carSpaceNumber)}
                        helperText={props.formik.touched.carSpaceNumber && props.formik.errors.carSpaceNumber}
                    />
                </td>

                <td></td>
            </tr>
            }
        </>
    }
}





export interface MeterReadingFieldsProps {
    formik: any;
    meterActive: boolean;
}

export function MeterReadingFields(props: MeterReadingFieldsProps) {

    const { formik } = props;

    return (
        <>
            <tr>

                <td>
                    <DatePicker
                        sx={{ width: "60%" }}
                        disabled={!props.meterActive}
                        label="Luentapäivämäärä"
                        slotProps={{
                            textField: {
                                placeholder: 'PP.KK.VVVV', onBlur: (event) => {
                                    formik.setFieldTouched('readingDate', true);

                                },
                                helperText: formik.touched.readingDate && formik.errors.readingDate,
                                error: formik.touched.readingDate && Boolean(formik.errors.readingDate)
                            },

                        }}
                        value={formik.values.readingDate}
                        onChange={(date) => {

                            formik.setValues(valus => ({ ...valus, readingDate: date }))
                        }}

                    />
                </td>
                <td></td>
            </tr>
            <tr>

                <td>
                    <TextField
                        fullWidth
                        disabled={!props.meterActive}
                        id="reading-txtfield"
                        name="meterReading"
                        label="Lukema"
                        sx={{ width: "60%" }}
                        value={formik.values.meterReading || ''}
                        onChange={formik.handleChange}

                        onBlur={formik.handleBlur}
                        error={formik.touched.meterReading && Boolean(formik.errors.meterReading)}
                        helperText={formik.touched.meterReading && formik.errors.meterReading}
                    />
                </td>
                <td></td>
            </tr>
        </>

    );


}

interface ISectionTitleProps {
    title: string;
    number: string;
    showOverlay: boolean;
}

function SectionTitle(props: ISectionTitleProps) {

    return (
        <>
            {props.showOverlay && <Box

                className="overlay-box"
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    //bgcolor: 'rgb(172 172 172 / 70%)',
                    //color: 'white',


                }} />}
            <Grid container spacing={2}>
                <Grid xs={10}>
                    <Typography sx={{ ml: 3, mt: 2, position: "relative" }} variant="h6" gutterBottom>
                        {props.title}
                    </Typography>
                </Grid>
                {false && <Grid xs={2}>
                    <Typography sx={{ mt: 2, pl: 3, position: "relative" }} variant="h3" gutterBottom>
                        {props.number}
                    </Typography>
                </Grid>}
            </Grid>
        </>
    );
}

interface IPersonInformationProps {
    formik: any;

    handleBack: any;
    handleNext: any;
    stepState: any;
    flowState: IFlowState;
    setAllFieldsTouched: any;
}
function PersonInformation(props: IPersonInformationProps) {

    const { formik } = props;

    return (
        <>
            <Paper sx={{ p: 2 }} elevation={3}>

                <SectionTitle key="section-title-person" title="Ilmoittajan tiedot" number="1" showOverlay={false} />

                <table className="meter-reading-table">

                    <tbody>
                        <tr>

                            <td>
                                <TextField
                                    required
                                    fullWidth
                                    autoFocus
                                    id="firstname-txtfield"
                                    name="firstName"
                                    label="Ilmoittajan etunimi"
                                    key="firstname-txtfield"
                                    value={formik.values.firstName || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />

                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextField
                                    required
                                    fullWidth
                                    key="lastname-txtfield"
                                    id="lastname-txtfield"
                                    name="lastName"
                                    label="Ilmoittajan sukunimi"

                                    value={formik.values.lastName || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />

                            </td>
                            <td></td>
                        </tr>

                        <tr>

                            <td>
                                <TextField
                                    required
                                    fullWidth
                                    id="phone-txtfield"
                                    name="phoneNumber"
                                    label="Puhelinnumero"

                                    value={formik.values.phoneNumber || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                />
                            </td>
                            <td></td>
                        </tr>

                        <tr>

                            <td>
                                <TextField

                                    fullWidth
                                    id="email-txtfield"
                                    name="email"
                                    label="Sähköposti"

                                    value={formik.values.email || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </td>
                            <td></td>
                        </tr>




                        <tr>

                            <td>
                                <FormControl fullWidth>
                                    <InputLabel required id="role-type-label">Ilmoittajan rooli </InputLabel>
                                    <Select
                                        labelId="role-type-label"
                                        displayEmpty={false}
                                        id="role"
                                        name="role"
                                        value={formik.values.role || ''}
                                        label="Ilmoittajan rooli"

                                        error={formik.touched.role && Boolean(formik.errors.role)}
                                        onChange={props.formik.handleChange}
                                        onBlur={props.formik.handleBlur}
                                    >
                                        <MenuItem value={RoleType.Resident}>Osakas</MenuItem>
                                        <MenuItem value={RoleType.Tenant}>Vuokralainen</MenuItem>
                                        <MenuItem value={RoleType.PropertyMaintenance}>Huoltoyhtiö</MenuItem>
                                        <MenuItem value={RoleType.Other}>Muu</MenuItem>

                                    </Select>
                                    {formik.touched.role && Boolean(formik.errors.role) && <FormHelperText error={true}>{formik.errors.role}</FormHelperText>}
                                </FormControl>
                            </td>
                            <td></td>

                        </tr>
                        {formik.values.role === RoleType.Other && <tr>

                            <td>
                                <TextField
                                    required
                                    fullWidth
                                    id="other-name-txtfield"
                                    name="otherName"
                                    label="Muu, mikä?"

                                    value={formik.values.otherName || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.otherName && Boolean(formik.errors.otherName)}
                                    helperText={formik.touched.otherName && formik.errors.otherName}
                                />
                            </td>
                            <td></td>
                        </tr>}

                        <NavigationButtons
                            nextActive={props.flowState.propertyActive}
                            handleBack={props.handleBack}
                            handleNext={props.handleNext}
                            stepState={props.stepState}
                            flowState={props.flowState}
                            formik={props.formik}
                            setAllFieldsTouched={props.setAllFieldsTouched}

                        />

                    </tbody>
                </table>

            </Paper>

        </>
    );
}

interface IPropertyInformationProps {
    formik: any;
    flowState: IFlowState;
    debouncedSetter: any;
    isLoading: boolean;
    formState: any;
    noValidPostalCode: any;
    inputValue: any;
    setInputValue: any;

    handleBack: any;
    handleNext: any;
    stepState: any;
    setAllFieldsTouched: any;
}
function PropertyInformation(props: IPropertyInformationProps) {

    const { formik, flowState, debouncedSetter, isLoading, formState, noValidPostalCode, inputValue, setInputValue } = props;

    return (
        <>
            <Paper sx={{ p: 2, position: "relative" }} elevation={3}>


                <SectionTitle key="section-title-property" title="Kohteen tiedot" number="2" showOverlay={false} />

                <table className="meter-reading-table">
                    <tbody>
                        {false && <tr>

                            <td>
                                <TextField
                                    disabled={!flowState.propertyActive}
                                    fullWidth
                                    id="housingcompany-txtfield"
                                    name="housingCompany"
                                    label="Taloyhtiö"

                                    value={formik.values.housingCompany || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.housingCompany && Boolean(formik.errors.housingCompany)}
                                    helperText={formik.touched.housingCompany && formik.errors.housingCompany}
                                />
                            </td>
                            <td></td>
                        </tr>}



                        <tr>

                            <td>
                                <FormControl>
                                    <TextField
                                        disabled={!flowState.propertyActive}
                                        required
                                        autoFocus
                                        fullWidth
                                        id="postalcode-txtfield"
                                        name="postalcode"
                                        label="Postinumero"

                                        value={formik.values.postalcode || ''}
                                        onChange={formik.handleChange}
                                        onBlur={(event) => {

                                            debouncedSetter(event.target.value);
                                            formik.handleBlur(event);
                                        }}
                                        error={formik.touched.postalcode && Boolean(formik.errors.postalcode)}
                                        helperText={formik.touched.postalcode && formik.errors.postalcode}
                                    />
                                    <FormHelperText error={false}>Aloita syöttämällä postinumero</FormHelperText>
                                </FormControl>
                            </td>
                            <td>
                                {isLoading && <CircularProgress color="success" />}
                            </td>
                        </tr>

                        <tr>

                            <td>
                                <TextField

                                    fullWidth
                                    id="city-txtfield"
                                    name="city"
                                    label="Kaupunki"
                                    disabled={true}
                                    value={formik.values.city || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                />
                            </td>
                            <td></td>
                        </tr>


                        <tr>

                            <td>

                                <Autocomplete
                                    disablePortal
                                    disabled={!flowState.propertyActive || noValidPostalCode(formik.values.postalcode, formik.errors.postalcode)}
                                    id="address"
                                    options={formState.addresses}
                                    autoSelect={true}
                                    openText="Avaa"
                                    clearText="Tyhjennä"
                                    autoHighlight={true}
                                    getOptionLabel={(option) => `${option.street} ${option.number}`}
                                    value={formik.values.address || null}
                                    noOptionsText="Ei osoitteita"
                                    onBlur={(event) => {


                                        setTimeout(() => {
                                            formik.setFieldTouched('address', true);
                                        }, 500);


                                    }}
                                    onChange={(event, newValue) => {

                                        //käyttäjä valitsee ddl:stä osoitteen
                                        formik.setValues(valus => ({ ...valus, address: newValue, meterType: null }));
                                    }}
                                    renderInput={(params) => <TextField disabled={!flowState.propertyActive} {...params} required error={formik.touched.address && Boolean(formik.errors.address)} label="Osoite" />}

                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {

                                        setInputValue(newInputValue);

                                    }}
                                />
                            </td>
                            <td></td>
                        </tr>
                        <StaircaseAndApartmentFields formik={formik} flowState={props.flowState} hidden={!formik.values.address || formik.errors.address} />

                        {false && <tr>

                            <td>
                                <TextField
                                    fullWidth
                                    id="additionalinfo-txtfield"
                                    name="additionalInfo"
                                    label="Osoitteen lisätieto"

                                    value={formik.values.additionalInfo || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.additionalInfo && Boolean(formik.errors.additionalInfo)}
                                    helperText={formik.touched.additionalInfo && formik.errors.additionalInfo}
                                />
                            </td>
                            <td></td>
                        </tr>}
                        <NavigationButtons nextActive={flowState.meterActive} handleBack={props.handleBack} handleNext={props.handleNext} stepState={props.stepState} flowState={props.flowState}
                            formik={props.formik} setAllFieldsTouched={props.setAllFieldsTouched} />
                    </tbody>
                </table>

            </Paper>

        </>
    );
}

interface IMeterInformationProps {
    formik: any;
    flowState: IFlowState;
    setAllFieldsTouched: any;

    handleBack: any;
    handleNext: any;
    stepState: any;
    notificationState: any;
    setNotificationState: any;

    meterState: IMeterState;
    isLoading: boolean;


}
function MeterInformation(props: IMeterInformationProps) {

    const { formik, flowState, setAllFieldsTouched } = props;

    const [asState, setASState] = useState<{ showFields: boolean }>({ showFields: false })

    useEffect(() => {
        if (formik.values.meterLocation == MeterLocation.Apartment && noApartmentOrStaircaseGiven(formik)) {
            setASState(state => ({ ...state, showFields: true }));
            setTimeout(() => { formik.setFieldTouched('apartment', true); }, 100);
            setTimeout(() => { formik.setFieldTouched('staircase', true); }, 100);
        }
    }, []);

    const showMeterSelection = (formik: any) => {
        if (formik.values.meterLocation == MeterLocation.Apartment && !formik.values.apartment && !formik.values.staircase) {
            return false;
        }

        return true;
    }

    return (
        <>
            <Paper sx={{ p: 2, position: "relative" }} elevation={3}>

                <SectionTitle key="section-title-meter" title="Mittaritiedot" number="3" showOverlay={false} />
                <table className="meter-reading-table" style={{ width: "96%" }}>
                    <tbody>
                        <tr>

                            <td>
                                <FormControl>
                                    <FormLabel disabled={!flowState.meterActive} id="reading-type">Ilmoitatko huoneisto- vai koko taloyhtiötä koskevia lukemia?</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="reading-type"

                                        value={formik.values.meterLocation}
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value={MeterLocation.Apartment}
                                            disabled={!flowState.meterActive}
                                            control={<Radio disabled={!flowState.meterActive} name="meterLocation" onChange={(event) => {

                                                if (noApartmentOrStaircaseGiven(formik)) {
                                                    setASState(state => ({ ...state, showFields: true }));
                                                }

                                                //formik.handleChange(event);
                                                formik.setValues(valus => ({ ...valus, meterLocation: MeterLocation.Apartment }));
                                                //formik.setTouched({ apartmentNumber: true, meterLocation: true }, true);
                                                formik.setFieldTouched('meterLocation', true, true);

                                                setTimeout(() => { formik.setFieldTouched('apartment', true); }, 100);
                                                setTimeout(() => { formik.setFieldTouched('staircase', true); }, 100);
                                            }} />}
                                            label="Asuntokohtainen mittari" />
                                        <FormControlLabel
                                            value={MeterLocation.Property}
                                            disabled={!flowState.meterActive}
                                            control={<Radio disabled={!flowState.meterActive} name="meterLocation" onChange={(event) => {

                                                setASState(state => ({ ...state, showFields: false }));
                                                formik.setValues(values => ({ ...values, meterLocation: MeterLocation.Property, apartment: null, staircase: null }));

                                                setTimeout(() => { formik.setFieldTouched('meterLocation', true, true); }, 100);

                                            }} />}
                                            label="Koko kiinteistön mittari" />



                                    </RadioGroup>
                                </FormControl>

                            </td>
                            <td></td>
                        </tr>
                        {asState.showFields && <StaircaseAndApartmentFields formik={formik} flowState={flowState} hidden={false} />}
                        {showMeterSelection(formik) && <>
                            <tr>

                                <td>
                                    <FormControl fullWidth>
                                        <InputLabel disabled={!flowState.meterActive} id="meter-type-label">Mittarityyppi </InputLabel>


                                        <MainMeterTypeMenu formik={formik} flowState={flowState} key="main-meter-type-menu" meterState={props.meterState} />

                                        {formik.touched.meterType && Boolean(formik.errors.meterType) && <FormHelperText error={true}>{formik.errors.meterType}</FormHelperText>}
                                    </FormControl>

                                </td>
                                <td>
                                    {props.isLoading && <CircularProgress color="success" />}
                                </td>
                            </tr>
                            {(formik.values.meterType === MeterType.GenericDistrictHeating || formik.values.meterType === MeterType.FivaldiDistrictHeating) && <MeterReadingFields meterActive={flowState.meterActive} formik={formik} />}

                            {(formik.values.meterType === MeterType.GenericWater || formik.values.meterType == MeterType.FivaldiWater) && <WaterMeterTypePicker meterState={props.meterState} meterActive={flowState.meterActive} formik={formik} />}
                            {(formik.values.meterType === MeterType.GenericWater || formik.values.meterType === MeterType.FivaldiWater) && formik.values.waterMeterType != null && <MeterReadingFields meterActive={flowState.meterActive} formik={formik} />}

                            {(formik.values.meterType === MeterType.GenericElectricity || formik.values.meterType == MeterType.FivaldiElectricity) && <ElectricityMeterFields meterState={props.meterState} meterActive={flowState.meterActive} formik={formik} />}
                            {(formik.values.meterType === MeterType.GenericElectricity || formik.values.meterType == MeterType.FivaldiElectricity) && <MeterReadingFields meterActive={flowState.meterActive} formik={formik} />}
                            <tr>

                                <td>
                                    <FormControlLabel
                                        disabled={!flowState.meterActive}
                                        required
                                        control={<Checkbox disabled={!flowState.meterActive} checked={formik.values.acceptTerms} name="acceptTerms" onChange={formik.handleChange} />}
                                        label={
                                            <span>
                                                <span>Vakuutan tiedot oikeiksi ja hyväksyn </span>
                                                <Link href={'https://uniko.fi/tietosuojaseloste'} target="_blank">tietosuoja- ja rekisteriselosteen</Link>

                                            </span>
                                        }
                                    />
                                </td>
                                <td></td>
                            </tr>
                            <NotificationDisplay
                                showNotification={props.notificationState.showNotification}
                                notificationType={props.notificationState.notificationType}
                                message={props.notificationState.message}
                                onClose={() => { props.setNotificationState(state => ({ ...state, showNotification: false })) }} />
                            <tr>

                                <td>
                                    {false && <Button disabled={!flowState.meterActive || !formik.isValid} color="success" variant="contained" type="submit" onClick={(event) => {
                                        setAllFieldsTouched(formik);
                                    }} >
                                        Lähetä lukema
                                    </Button>}
                                </td>
                                <td></td>
                            </tr>
                        </>}
                        <NavigationButtons nextActive={false} handleBack={props.handleBack} handleNext={props.handleNext} stepState={props.stepState} flowState={props.flowState} formik={props.formik} setAllFieldsTouched={props.setAllFieldsTouched} />
                    </tbody>

                </table>

            </Paper>

        </>
    );
}


function noApartmentOrStaircaseGiven(formik) {
    if ((!formik.values.staircase || formik.values.staircase?.length == 0) && (!formik.values.apartment || formik.values.apartment?.length == 0)) {
        return true;
    }
    return false;
}


interface IValueSendMessageProps {
    setStepState: any;
}

function ValueSendMessage(props: IValueSendMessageProps) {
    return (<>
        <Paper sx={{ p: 2, position: "relative", height: "100%", minHeight: 100 }} elevation={3}>

            <Grid container spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid xs={1}>
                    <CheckCircleOutlineIcon fontSize="large" color="success" />
                </Grid>

                <Grid xs={11}>
                    <Typography sx={{ ml: 1 }}>
                        Mittaritiedot lähetetty!
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid xs={1}>

                </Grid>

                <Grid xs={11}>
                    <Button
                        color="inherit"
                        variant="contained"
                        onClick={(event) => { props.setStepState(state => ({ ...state, activeStep: 0 })) }}
                        sx={{ mt: 3 }}
                    >
                        Lisää uusi lukema
                    </Button>
                </Grid>
            </Grid>




        </Paper>
    </>)
}


interface INavigationButtonsProps {
    stepState: any;
    handleBack: any;
    handleNext: any;
    nextActive: boolean;
    flowState: any;
    formik: any;
    setAllFieldsTouched
}

function NavigationButtons(props: INavigationButtonsProps) {

    const { stepState, handleBack, handleNext } = props;

    return (
        <tr>
            <td>
                <div style={{ display: "flex", }}>

                    {stepState.activeStep > 0 && <Button
                        color="inherit"
                        variant="outlined"
                        disabled={stepState.activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Palaa
                    </Button>}



                    {stepState.activeStep === steps.length - 1 ? <></> : <Button sx={{ marginLeft: "auto" }} disabled={!props.nextActive} onClick={handleNext} variant="contained" color={!props.nextActive ? "inherit" : "success"}>
                        Seuraava
                    </Button>}


                    {stepState.activeStep == steps.length - 1 ? <Button sx={{ marginLeft: "auto" }} disabled={!props.flowState.meterActive || !props.formik.isValid} color="success" variant="contained" type="submit" onClick={(event) => {
                        props.setAllFieldsTouched(props.formik);
                    }} >
                        Lähetä lukema
                    </Button> : <></>}
                </div>
            </td>
            <td></td>
        </tr>
    );
}

interface IStaircaseAndApartmentFields {
    flowState: any;
    formik: any;
    hidden: boolean;
}
function StaircaseAndApartmentFields(props: IStaircaseAndApartmentFields) {

    const { flowState, formik } = props;

    if (props.hidden) {
        return <></>
    }


    return (
        <>
            <tr>

                <td>
                    <TextField

                        id="staircase-txtfield"
                        disabled={!flowState.propertyActive}
                        name="staircase"
                        label="Rappu"
                        inputProps={{
                            maxLength: 1,
                        }}

                        value={formik.values.staircase || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.staircase && Boolean(formik.errors.staircase)}
                        helperText={formik.touched.staircase && formik.errors.staircase}
                    />
                </td>
                <td></td>
            </tr>

            <tr>

                <td>
                    <TextField

                        id="apartment-txtfield"
                        disabled={!flowState.propertyActive}
                        name="apartment"
                        label="Huoneisto"
                        inputProps={{
                            maxLength: 6,
                        }}
                        value={formik.values.apartment || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.apartment && Boolean(formik.errors.apartment)}
                        helperText={formik.touched.apartment && formik.errors.apartment}
                    />
                </td>
                <td></td>
            </tr>
        </>
    );
}

export interface IMainMeterTypeMenu {
    meterState: IMeterState;
    flowState: any;
    formik: any;
}

export function MainMeterTypeMenu(props: IMainMeterTypeMenu) {

    const { flowState, formik } = props;

    if (props.meterState.fetchSuccess) {
        const waterMeter = props.meterState.meters.find(m => m.meterType == MeterType.FivaldiColdWater || m.meterType == MeterType.FivaldiHotWater);
        const electricityMeter = props.meterState.meters.find(m => m.meterType == MeterType.FivaldiWinterElectricity || m.meterType == MeterType.FivaldiApartmentElectricity || m.meterType == MeterType.FivaldiDayElectricity ||
            m.meterType == MeterType.FivaldiNightElectricity || m.meterType == MeterType.FivaldiOverallElectricity);

        const remoteHeating = props.meterState.meters.find(m => m.meterType == MeterType.FivaldiRemoteHeating);

        return (
            <>
                <Select
                    disabled={!flowState.meterActive}
                    labelId="meter-type-label"
                    id="meterType"
                    name="meterType"
                    value={formik.values.meterType || ''}
                    error={formik.touched.meterType && Boolean(formik.errors.meterType)}
                    label="Mittarityyppi"
                    onBlur={props.formik.handleBlur}
                    onChange={(event) => {


                        formik.setValues(values => ({
                            ...values,
                            meterType: event.target.value,
                            fivaldiMeterId: event.target.value == MeterType.FivaldiDistrictHeating ? remoteHeating.id : null
                        }))

                        formik.handleChange(event);

                    }}>
                    {waterMeter && <MenuItem value={MeterType.FivaldiWater}>Vesi</MenuItem>}
                    {electricityMeter && <MenuItem value={MeterType.FivaldiElectricity}>Sähkö</MenuItem>}
                    {remoteHeating && <MenuItem value={MeterType.FivaldiDistrictHeating}>Kaukolämpö</MenuItem>}
                </Select>
            </>
        );


    } else {
        return (
            <>
                <Select
                    disabled={!flowState.meterActive}
                    labelId="meter-type-label"
                    id="meterType"
                    name="meterType"
                    value={formik.values.meterType || ''}
                    error={formik.touched.meterType && Boolean(formik.errors.meterType)}
                    label="Mittarityyppi"
                    onBlur={props.formik.handleBlur}
                    onChange={formik.handleChange}>

                    <MenuItem value={MeterType.GenericWater}>Vesi</MenuItem>
                    <MenuItem value={MeterType.GenericElectricity}>Sähkö</MenuItem>
                    <MenuItem value={MeterType.GenericDistrictHeating}>Kaukolämpö</MenuItem>
                </Select>



            </>
        );
    }
}