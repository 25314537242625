import { RoleType } from "../meterreading/components/MeterReadingForm";

//export enum ServiceRequestType {
//    Maintenance = 1,
//    Cleaning = 2,
//    OutdoorAreaMaintenance = 3
//}

export enum TicketCategoryTypes {
    All = 0,
    Yhteydenottopyynto = 1,
    Vastikelaskut = 2,
    Vastikereskontraselvitykset = 3,
    Maksuhuomautukset = 4,
    HuoltoJaKorjaus = 5,
    Hairioilmoitus = 6,
    Mittarilaskutus = 7,
    Autopaikat = 8,
    Saunavuorot = 9,
    Avaintilaus = 10,
    Muu = 11,
    Siivous = 12,
    Ulkoalueidenhoito = 13
}

export interface IPublicServiceRequest {
    firstName: string | null;
    lastName: string | null;
    postalcode: string | null;
    city: string | null;
    phoneNumber: string | null;
    additionalInfo: string | null;
    addressDDLValue: any;
    acceptTerms: boolean;
    type: TicketCategoryTypes | null;
    detectedFault: DetectedFaultType | null;
    email: string | null;
    role: RoleType | null;
    otherName: string | null;
}


export enum DetectedFaultType {
    WaterAndPipes = 1,
    Heating = 2,
    Electricity = 3,
    Other = 4
}