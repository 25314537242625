import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FormInputProps } from "./PublicFormInputProps";
import React from "react";
export const PublicFormInputText = ({ name, control, label, disabled, multiline = false, required = false, helperText = null }: FormInputProps) => {

    const isDisabled = disabled ?? false;
    
    return (

        <Controller
            name={name}
            key={`${name}-controller` }
            control={control}
            render={({
                field: { onChange, value, onBlur },
                fieldState: { error, isTouched },
                formState,
            }) => {

                return <TextField
                    helperText={(isTouched && error) ? error.message : helperText ? helperText : ''}
                    size="medium"
                    disabled={isDisabled}
                    error={!!error && isTouched}

                    onChange={onChange}
                    value={value ?? ''}
                    required={required}
                    fullWidth
                    key={`${name}-textfield`}
                    label={label}
                    multiline={multiline}
                    rows={multiline ? 4 : 1}
                    onBlur={onBlur}
                    variant="outlined"
                />
            }
}
            />
        
    );
};


