import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    Link,
    RouterProvider,
} from "react-router-dom";

import { MeterSubmitIndex } from "./meterreading";
import { FeedbackFormApp } from "./feedback/FeedbackFormApp";
import { Stack } from "@mui/material";
import { ServiceRequestForm } from "./servicerequest/PublicServiceRequestForm";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ChangeOfAddressForm } from "./changeofaddress/ChangeOfAddressForm";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "dayjs/locale/fi";   
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fiFI } from '@mui/x-date-pickers/locales';
import { fiFI as corefiFI } from '@mui/material/locale';

const router = createBrowserRouter([
    {
        path: "/",
        element: <>
            <Stack direction="column">
                <Link to={`meter-reading`}>Mittarilukema (asukas)</Link>
                <Link to={`feedback/E5967443-BE4E-4DD8-A0E5-4B1809FFADEE`}>Palaute</Link>
                <Link to={`service-request`}>Huoltopyyntö</Link>
                <Link to={`change-of-address`}>Muuttoilmoitus</Link>
            </Stack>

        </>,
        errorElement: <>Error</>,
    },
    {
        path: "/meter-reading",
        element: <MeterSubmitIndex />,
    },
    {
        path: "/feedback/:id",
        element: <FeedbackFormApp />,
    },
    {
        path: "/service-request",
        element: <ServiceRequestForm />,
    },
    {
        path: "/change-of-address",
        element: <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi"><ChangeOfAddressForm /></LocalizationProvider>,
    },

]);
const queryClient = new QueryClient()

const theme = createTheme(
    {},
    fiFI, // use 'de' locale for UI texts (start, next month, ...)
    corefiFI
);


ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
            </QueryClientProvider>
        </ThemeProvider>
    </React.StrictMode>
);


function MainApp() {




    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
            </QueryClientProvider>
        </React.StrictMode>
    );
}

