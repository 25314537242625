import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import React, { useMemo } from "react";
import { Controller } from "react-hook-form";

interface IComponentOption { value: any; label: string }

interface IPublicFormInputRadioGroupProps {
    label: string;
    name: string;
    control: any;
    required: boolean;
    options: IComponentOption[];
}

export function PublicFormInputRadioGroup(props: IPublicFormInputRadioGroupProps) {


    const { control, label, name, options, required } = props;

    const generateSingleOptions = (options: IComponentOption[]) => {
        return options.map(({ value, label }) => {
            return (
                <FormControlLabel key={`${value}-rielement`} value={value} control={<Radio />} label={label} />
            );
        });
    };

    const optionItems = useMemo(() => {
        return generateSingleOptions(options)
    }, [options])

    return (<>
        <FormControl>
            <FormLabel id={`${name}-radio-buttons-group-label`}>{label}</FormLabel>

            <Controller
                render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <RadioGroup
                   
                        onChange={onChange}
                        onBlur={onBlur}
                        aria-labelledby={`${name}-radio-buttons-group-label`}
                        value={value || ''}
                        name={name}
                    >
                        {optionItems }
                    </RadioGroup>
                )}
                control={control}
                name={name}
            />

           
        </FormControl>

    </>);
}