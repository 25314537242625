import { Alert, AlertTitle, Box, Button, LinearProgress, Paper, Rating, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { FormikProps, useFormik } from "formik";
import React, { useEffect, useState } from "react";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StarIcon from '@mui/icons-material/Star';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from "@mui/x-date-pickers";
import { useParams } from "react-router-dom";
import { object, string } from "yup";
import { executeFetchPOST } from "../common/utils";
export enum NotificationType {
    Success = 1,
    Error = 2
}

interface IFeedbackPayload {
    id: string;
    text: string;
}




const validationSchema = object().shape({

    text: string().required('Pakollinen').typeError("Pakollinen")




})


export interface IStreetAddress {
    id: number;
    street: string;
    number: string;
    postalcode: string;
}

/*const steps = ['Omat tiedot', 'Palaute'];*/
const steps = ['Palaute'];
interface IFlowState {
    propertyActive: boolean;
    meterActive: boolean;
}


interface IFeedbackData {
 
    text: string;
    rating: number | null
}


interface IFeedbackFormData {
    title: string
}


export function FeedbackForm() {

    let { id } = useParams();

    console.log(`id parametri: ${id}`);

    const [stepState, setStepState] = useState<{ activeStep: number }>({
        activeStep: 0
    });




    const [loadingState, setLoadingState] = useState<{ isLoadingInitialFormData: boolean; }>({
        isLoadingInitialFormData: false
    });

    const [feedbackFormData, setFeedbackFormData] = useState<IFeedbackFormData>({
        title: ''
    })


    useEffect(() => {
        setLoadingState(state => ({ ...state, isLoadingInitialFormData: true }));
        executeFetchPOST(`${process.env.API_URL}/submit/feedback-formdata`, {}, (response: { success: boolean, title: string }) => {
            setLoadingState(state => ({ ...state, isLoadingInitialFormData: false }));

            if (response.success) {
                setFeedbackFormData(state => ({ ...state, title: response.title }));
            }

        });

    }, [])


    const formik = useFormik<IFeedbackData>({
        initialValues: {
            text: null,
            rating: 4

        },

        validationSchema: validationSchema,
        onSubmit: (values, helpers) => {




            const payload: IFeedbackPayload = {
                id,
                ...values,

            }

            executeFetchPOST(`${process.env.API_URL}/submit/save-feedback`, payload, (response: { success: boolean }) => {

                if (response.success) {
                    setTimeout(() => { helpers.resetForm() }, 100);

                    setStepState((state) => ({ ...state, activeStep: 1 }));
                    setNotificationState(state => ({ showNotification: true, message: `Kiitos palautteesta`, notificationType: NotificationType.Success }));

                } else if (!response.success) {
                    //show error message
                    setNotificationState(state => ({ showNotification: true, message: 'Palautteen lähettäminen epäonnistui', notificationType: NotificationType.Error }));
                }



            });

        },
    });


    const [notificationState, setNotificationState] = useState<{ showNotification: boolean, message: string, notificationType: NotificationType }>({
        showNotification: false,
        message: '',
        notificationType: null
    });

    const [flowState, setFlowState] = useState<IFlowState>({
        propertyActive: false,
        meterActive: false
    });

   

    const setAllFieldsTouched = (formik) => {

        setTimeout(() => {
            formik.setTouched({
                firstName: true,
                lastName: true,
                phoneNumber: true,
                email: true,
                text: true

            }, true);
        }, 100);
    }

    const isLoading = loadingState.isLoadingInitialFormData;

    return (
        <>
            {isLoading && <LinearProgress />}
            {!isLoading &&  < div style={{ width: "100%" }}>

                <form onSubmit={(event) => {

                    setAllFieldsTouched(formik);

                    formik.handleSubmit(event)
                }}>

                    <Box sx={{ width: '100%' }}>
                        

                        <>
                            <Box sx={{ mt: 2 }}></Box>

                            {stepState.activeStep == 0 && <Feedback
                                formik={formik}
                                setAllFieldsTouched={setAllFieldsTouched}
                                feedbackFormData={feedbackFormData}
                            />}

                            {stepState.activeStep == 1 && <ValueSendMessage  />}

                        </>

                    </Box>

                </form>
                {false && <NotificationDisplay
                    showNotification={notificationState.showNotification}
                    notificationType={notificationState.notificationType}
                    message={notificationState.message}
                    onClose={() => { setNotificationState(state => ({ ...state, showNotification: false })) }} />}
            </div>}

        </>)
}


export function NotificationDisplay(props: { showNotification: boolean, message: string, notificationType: NotificationType, onClose: () => void }) {

    if (props.showNotification) {

        //if (props.notificationType == NotificationType.Success) {
        //    return <>
        //        <Alert severity="success" onClose={() => { props.onClose() }}>
        //            <AlertTitle>Valmis</AlertTitle>
        //            {props.message}
        //        </Alert>

        //    </>
        //} else

        if (props.notificationType == NotificationType.Error) {
            return <>
                <tr>
                    <td>
                        <Alert severity="error" onClose={() => { props.onClose() }}>
                            <AlertTitle>Virhe</AlertTitle>
                            {props.message}
                        </Alert>
                    </td>
                    <td>
                    </td>
                </tr>
            </>
        }
    }

    return <></>

}

export interface IWaterMeterReadingProps {
    formik: any;
    meterActive: boolean;

}

interface ISectionTitleProps {
    title: string;
    number: string;

}

function SectionTitle(props: ISectionTitleProps) {

    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={10}>
                    <Typography sx={{ ml: 1, mt: 2, position: "relative" }} variant="h6" gutterBottom>
                        {props.title}
                    </Typography>
                </Grid>
                {false && <Grid xs={2}>
                    <Typography sx={{ mt: 2, pl: 3, position: "relative" }} variant="h3" gutterBottom>
                        {props.number}
                    </Typography>
                </Grid>}
            </Grid>
        </>
    );
}




const labels: { [index: string]: string } = {
    0.5: 'Surkea',
    1: 'Surkea+',
    1.5: 'Huono',
    2: 'Huono+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Hyvä',
    4: 'Hyvä+',
    4.5: 'Loistava',
    5: 'Loistava+',
};

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

interface IFeedbackProps {
    formik: FormikProps<IFeedbackData>;
    feedbackFormData: IFeedbackFormData;

    setAllFieldsTouched: any;
}

function Feedback(props: IFeedbackProps) {
    const { formik } = props;

    //const [value, setValue] = React.useState<number | null>(2);
    const [hover, setHover] = React.useState(-1);

    return <>
        <Paper sx={{ p: 2 }} elevation={3}>

            {false && <SectionTitle key="section-title-person" title="Palaute" number="1" />}
            <Typography sx={{ mt: 2, mb: 3, position: "relative" }} variant="h5" >
                {props.feedbackFormData.title}
            </Typography>
            <Box
                sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                    mb: 3
                }}
            >

                <Rating
                    name="hover-feedback"
                    value={formik.values.rating}
                    precision={0.5}
                    size="large"
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                        //setValue(newValue);
                        formik.setFieldValue('rating', newValue, true);
                    }}
                    onChangeActive={(event, newHover) => {
                        setHover(newHover);
                    }}
                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
                {formik.values.rating !== null && (
                    <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : formik.values.rating]}</Box>
                )}
            </Box>

            <table className="feedback-table" style={{ width: "100%" }}>

                <tbody>
                    <tr>
                        <td>

                        </td>
                        <td></td>
                    </tr>


                    <tr>

                        <td>
                            <TextField
                                required
                                fullWidth
                                autoFocus
                                multiline
                                rows={10}
                                id="text-txtfield"
                                name="text"
                                label="Palautteesi"
                                key="text-txtfield"
                                value={formik.values.text || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.text && Boolean(formik.errors.text)}
                                helperText={formik.touched.text && formik.errors.text}
                            />

                        </td>
                        <td>
                        </td>
                    </tr>



                    <tr>
                        <td>
                            <div style={{ display: "flex", }}>


                                <Button sx={{ marginLeft: "auto" }} disabled={!props.formik.isValid} color="success" variant="contained" type="submit" onClick={(event) => {
                                    props.setAllFieldsTouched(props.formik);
                                }} >
                                    Lähetä palaute
                                </Button>
                            </div>
                        </td>
                        <td></td>
                    </tr>

                </tbody>
            </table>

        </Paper>

    </>


}

interface IValueSendMessageProps {
    
}

function ValueSendMessage(props: IValueSendMessageProps) {
    return (<>
        <Paper sx={{ p: 2, position: "relative", height: "100%", minHeight: 100 }} elevation={3}>

            <Grid container spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid xs={1}>
                    <CheckCircleOutlineIcon fontSize="large" color="success" />
                </Grid>

                <Grid xs={11}>
                    <Typography sx={{ ml: 1 }}>
                        Kiitos palautteesta
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid xs={1}>

                </Grid>

                
            </Grid>




        </Paper>
    </>)
}


interface INavigationButtonsProps {
    stepState: any;
    handleBack: any;
    handleNext: any;
    nextActive: boolean;
    flowState: any;
    formik: any;
    setAllFieldsTouched
}

function NavigationButtons(props: INavigationButtonsProps) {

    const { stepState, handleBack, handleNext } = props;

    return (
        <tr>
            <td>
                <div style={{ display: "flex", }}>

                    {stepState.activeStep > 0 && <Button
                        color="inherit"
                        variant="outlined"
                        disabled={stepState.activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Palaa
                    </Button>}



                    {stepState.activeStep === steps.length - 1 ? <></> : <Button sx={{ marginLeft: "auto" }} disabled={!props.nextActive} onClick={handleNext} variant="contained" color={!props.nextActive ? "inherit" : "success"}>
                        Seuraava
                    </Button>}


                    {stepState.activeStep == steps.length - 1 ? <Button sx={{ marginLeft: "auto" }} disabled={!props.formik.isValid} color="success" variant="contained" type="submit" onClick={(event) => {
                        props.setAllFieldsTouched(props.formik);
                    }} >
                        Lähetä palaute
                    </Button> : <></>}
                </div>
            </td>
            <td></td>
        </tr>
    );
}
