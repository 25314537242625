import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Autocomplete, Box, Button, Card, CircularProgress, FormControl, FormHelperText, IconButton, Link, Paper, Stack, Step, StepLabel, Stepper, TextField, TextFieldProps, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Control, Controller, SubmitHandler, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import { ChangeAddressNotificationType, IChangeAddress, MeterReadingAllowed } from './change-address-types';
import { IStepState, useAddressHandling, useStepState } from '../common/common-hooks';
import { executeFetchPOST2 } from '../common/utils';
import { IAddressResponse, IGeneralServerResponse, IStreetAddress } from '../common/common-types';
import { UseQueryResult, useMutation } from '@tanstack/react-query';
import { useYupValidationResolver } from '../common/componentUtils';
import { bool, number, object, string, date, array } from "yup";
import libphonenumber from 'google-libphonenumber';
import { RoleType } from '../meterreading/components/MeterReadingForm';
import { PublicFormInputText } from '../common/PublicFormInputText';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs'
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import "./change-address.less";
import { PublicFormInputRadioGroup } from '../common/PublicFormInputRadioGroup';
import { DevTool } from '@hookform/devtools';
import { PublicFormInputDropdown } from '../common/PublicFormInputDropdown';
import { AddressCollector } from './AddressCollector';
import * as yup from 'yup';
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

import { yupResolver } from '@hookform/resolvers/yup';
import { PublicFormInputCheckbox } from '../common/PublicFormInputCheckbox';
import { SendErrorNotification } from '../servicerequest/PublicServiceRequestForm';

//type Cat = {
//    name: string;
//}

//type Dog = {
//    name: string;
//    age: number;
//    breed: number;
//}

//type CatAndDog = Cat & Dog;
//type CatOrDog = Cat | Dog;
//const v1: CatAndDog = { name: 'test' };

//const v2: CatOrDog = {name: 'tset'}


//interface IMessage {
//    success: boolean;
//}

//interface Response1 extends IMessage {
//    payload: any;

//}


//interface Response1 {
//    added: number;
//}

//const r1: Response1 = { success: true, payload: {} }

//const maybe: unknown = 4;

//const a1: number = maybe as number;


type stringOrNull = string | null;


const validationSchema = object({
    firstName: string().required("Pakollinen").typeError('Pakollinen'),
    lastName: string().required("Pakollinen").typeError('Pakollinen'),
    phoneNumber: string().test({
        name: 'is-valid-phonenumber', // Your custom error id
        message: 'Arvon on oltava puhelinnumero',
        test: function () {
            const { phoneNumber } = this.parent; // Access the object data, here this.parent contains your data

            if (phoneNumber) {
                try {
                    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, 'FI');

                    const value = phoneUtil.isValidNumber(number);


                    return value;
                } catch (ex) {
                    return false;
                }
            }
            return false;

        }
    }).required('Arvon on oltava puhelinnumero').typeError('Arvon on oltava puhelinnumero').matches(/^[+]?[0-9 ]*$/, 'Arvon on oltava puhelinnumero'),
    email: string().email('Sähköpostiosoite on virheellinen').required('Pakollinen').typeError('Arvon on oltava sähköposti').nullable(),
    detectedFault: number().required('Aihealue on pakollinen'),
    additionalInfo: string().required('Pakollinen').nullable(),
    acceptTerms: bool().oneOf([true], 'Sinun on hyväksyttävä tietosuoja- ja rekisteriseloste'),
    postalcode: string().required('Pakollinen').nullable(),
    role: number().required('Rooli on pakollinen').typeError('Rooli on pakollinen'),
    otherName: string().when('role', {
        is: RoleType.Other,
        then: string().required('Kenttä on pakollinen').typeError('Kenttä on on pakollinen'),
        otherwise: string().notRequired().nullable()
    }),
})

const personInformationSchema = yup.object({
    typeOfNotification: number().nullable().required('Pakollinen').typeError('Pakollinen'),
    firstName: string().required("Pakollinen").typeError('Pakollinen'),
    lastName: string().required("Pakollinen").typeError('Pakollinen'),
    phoneNumber: string().test({
        name: 'is-valid-phonenumber', // Your custom error id
        message: 'Arvon on oltava puhelinnumero',
        test: function () {
            const { phoneNumber } = this.parent; // Access the object data, here this.parent contains your data

            if (phoneNumber) {
                try {
                    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, 'FI');

                    const value = phoneUtil.isValidNumber(number);


                    return value;
                } catch (ex) {
                    return false;
                }
            }
            return false;

        }
    }).required('Arvon on oltava puhelinnumero').typeError('Arvon on oltava puhelinnumero').matches(/^[+]?[0-9 ]*$/, 'Arvon on oltava puhelinnumero'),
    email: string().email('Sähköpostiosoite on virheellinen').required('Pakollinen').typeError('Arvon on oltava sähköposti').nullable(),
    role: number().required('Rooli on pakollinen').typeError('Rooli on pakollinen'),
    otherName: string().when('role', {
        is: RoleType.Other,
        then: string().required('Kenttä on pakollinen').typeError('Kenttä on on pakollinen'),
        otherwise: string().notRequired().nullable()
    }),
})

const propertyInformationSchema = object({
    changeOfAddressDate: date().nullable().required("Pakollinen"),
    fromAddress: object({
        postalcode: string().nullable().required('Postinumero on pakollinen 1').typeError('Postinumero on pakollinen 1'),
        addressDDLValue: object().required('Osoite on pakollinen'),
    }).required('Pakollinen'),
    toAddress: object({
        postalcode: string().required('Postinumero on pakollinen 2').typeError('Postinumero on pakollinen 2'),
        addressDDLValue: object().required('Osoite on pakollinen'),
    }).required('Pakollinen'),
    //postalcode: string().required('Postinumero on pakollinen').typeError('Postinumero on pakollinen'),
    //addressDDLValue: object().required('Osoite on pakollinen'),
    // newAddress: string().required('Pakollinen').typeError('Pakollinen')

})

const meterReadinginformationSchema = object({
    meterReadingAllowed: number().required('Pakollinen').typeError('Pakollinen'),
    additionalInfo: string().notRequired().typeError('Pakollinen')

})


const otherInformationSchema = object({
   
    persons: array(
        object({
            firstName: string().required("Pakollinen").typeError('Pakollinen'),
            lastName: string().required("Pakollinen").typeError('Pakollinen'),
            dob: date().required("Pakollinen").nullable(),
        })),
   acceptTerms: bool().oneOf([true], 'Sinun on hyväksyttävä tietosuoja- ja rekisteriseloste'),
})



const steps = ['Ilmoittajan tiedot', 'Kohteen tiedot', 'Mittarinluenta', 'Muut tiedot ja lähetys'];

export function ChangeOfAddressForm() {

    const resolver2 = useYupValidationResolver(validationSchema);
    //@ts-ignore
    const personInformationResolver = yupResolver(personInformationSchema);
    //@ts-ignore
    const propertyInformationResolver = yupResolver(propertyInformationSchema);
    //@ts-ignore
    const meterReadingInfoResolver = yupResolver(meterReadinginformationSchema);
    //@ts-ignore
    const otherInformationResolver = yupResolver(otherInformationSchema);

    const { addressQuery, debouncedSetter, inputValue, setInputValue } = useAddressHandling();

    const methods = useForm<IChangeAddress>({
        //@ts-ignore
        defaultValues: {
            typeOfNotification: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            email: null,
            changeOfAddressDate: null,
            role: null,
            otherName: null,
            persons: [],

            isMoverShareholder: false,
            isPropertySold: false,
            contractDeliveredEstateManager: false,
            acceptTerms: false

        },
        //@ts-ignore
        resolver: async (data, context, options: any) => {


            //console.log(data);

            if (stepState.activeStep == 0) {
                console.log(`use personvalidation`);
                const result = await personInformationResolver(data, context, options);
                //console.log(result);
                //const isValid = Object.keys(result.errors).length == 0;
                //console.log(`invalidation, isvalid: ${isValid}`)
                //setFlowState(state => ({ ...state, personValid: isValid }))
                return result;

            } else if (stepState.activeStep == 1) {
                console.log(`use propertyvalidation`);
                const result = await propertyInformationResolver(data, context, options);
                //const isValid = Object.keys(result.errors).length == 0;
                //console.log(result);
                //setFlowState(state => ({ ...state, propertyValid: isValid }))

                return result;
            } else if (stepState.activeStep == 2) {
                console.log(`use meterreadingvalidation`);
                return await meterReadingInfoResolver(data, context, options);
            } else if (stepState.activeStep == 3) {

                //@ts-ignore
                const result = await otherInformationResolver(data, context, options);
                console.log(`result:::`)
                console.log(result);
                return result;

            }
            return await meterReadingInfoResolver(data, context, options);
            //console.log(`validation:::`);
            // console.log(result);

            //if (result.errors.firstName || result.errors.lastName || result.errors.phoneNumber || result.errors.otherName) {
            //    setFlowState(state => ({ ...state, propertyActive: false }))
            //} else {
            //    setFlowState(state => ({ ...state, propertyActive: true }))
            //}


            //return await resolver2(data)
        },
        mode: "onTouched"

    })

    const { trigger, watch } = methods;

   


    const { handleBack, handleNext, stepState, backToStart, onSendSuccess, onSendError, sendError, message } = useStepState(trigger);

    const t = methods.control

    const { mutate: sendChangeOfAddress, isPending: isPendingSend } = useMutation({
        mutationFn: (changeOfAddress: IChangeAddress) => {

            return executeFetchPOST2(`${process.env.API_URL}/submit/save-change-address`, changeOfAddress).then((response: IGeneralServerResponse) => {

                if (response.success) {
                    //viewState.onTicketSend(response.message)
                    onSendSuccess(response.message);

                } else if (!response.success) {
                    //viewState.onSendTicketError(response.message)
                    onSendError(response.message);
                }

            });

        },



    })

    const onSubmit: SubmitHandler<IChangeAddress> = (data) => {
        //const withCity = { ...data, city: addressQuery.data.postcodefiname };
        sendChangeOfAddress(data)
    }

    const {
        register,
        handleSubmit,
        
        getFieldState,
        control,
        formState: { isValid, isDirty, isSubmitSuccessful },
        setValue,
        reset,
        setError,
        getValues,



    } = methods;


    return (<div style={{ width: "100%" }}>




        <form className="change-address-form" onSubmit={handleSubmit(onSubmit)}>

            <Box sx={{ width: '100%' }}>

                <Stepper activeStep={stepState.activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                <>
                    <Box sx={{ mt: 2 }}></Box>



                    {stepState.activeStep == 0 && <PersonInformation
                        control={methods.control}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        stepState={stepState}
                        isFormValid={methods.formState.isValid}
                    />}
                    {stepState.activeStep == 1 && <ChangeAddressInformation
                        control={methods.control}
                        setValue={methods.setValue}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        stepState={stepState}
                        isFormValid={methods.formState.isValid}
                        addressQuery={addressQuery}
                        debouncedSetter={debouncedSetter}

                        inputValue={inputValue}
                        setInputValue={setInputValue}
                    />}
                    {stepState.activeStep == 2 && <MeterReadingInfo
                        control={methods.control}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        stepState={stepState}
                        isFormValid={methods.formState.isValid}

                    />}

                    {stepState.activeStep == 3 && <ChangeAddressPersonsInfo
                        control={methods.control}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        stepState={stepState}
                        isFormValid={methods.formState.isValid}
                        watch={watch}
                        sendError={sendError}
                        message={message}
                        register={methods.register}
                        methods={methods}

                    />}


                    {stepState.activeStep == 4 && <ValueSendMessage
                        message={message}
                        backToStart={() => {
                            methods.reset();
                            backToStart();
                        }} />}

                </>

            </Box>

        </form>
        <DevTool control={control} />
    </div>);
}


interface IChangeAddressPersonsInfo {
    control: Control<IChangeAddress, any>;
    stepState: IStepState;
    handleBack: () => void;
    handleNext: () => void;
    isFormValid: boolean;
    watch: any;
    sendError: boolean;
    message: stringOrNull;
    register: any;
    methods: any;

}
export function ChangeAddressPersonsInfo(props: IChangeAddressPersonsInfo) {    

    const { control, watch, methods } = props;

    const { fields, append, remove, insert, replace, update } = useFieldArray({
        name: "persons",
        control
    });

    const isShareHolder = watch("isMoverShareholder", false);
    const isPropertySold = watch("isPropertySold", false);

    const firstNameValue: string = watch('firstName', null);
    const lastNameValue: string = watch('lastName', null);

    useEffect(() => {

        if (firstNameValue && lastNameValue) {

            

            update(0, {
                dob: null, firstName: firstNameValue, lastName: lastNameValue, id: null
            })
        }

    }, [firstNameValue, lastNameValue])


    return (
        <>
            <Card
                id="card-1"
                key='card-1'
                sx={{
                    maxWidth: "100% !important",
                    p: 3
                }} >
                <Typography variant="h5" gutterBottom>
                    Muuttavat henkilöt
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    Listaa alle kaikki muuttavat henkilöt. Voit valita seuraavan henkilön painamalla "Lisää henkilö".

                </Typography>
                <Grid
                    container
                    spacing={3}
                    key='persons-grid-container'
                >

                    <Grid xs={12}>
                        {fields.map((field, index) => {
                            return (
                                <Paper sx={{mt: 2, p: 2 }} >
                                    <Stack>
                                        <IconButton sx={{ marginLeft: "auto" }} title="Poista" aria-label="delete" onClick={(event) => {
                                            remove(index);
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>

                                    <Stack  direction="column" spacing={2}>

                                        <Stack spacing={{xs: 2, md: 2}} direction={{ md: 'row', sm: 'column' }}>

                                           


                                            <PublicFormInputText
                                                key={`${field.id}-firstname`}
                                                name={`persons.${index}.firstName`}
                                                control={control}
                                                label="Etunimi"
                                                required={true}
                                                multiline={false}
                                            />

                                           


                                            <PublicFormInputText
                                                key={`${field.id}-lastname`}
                                                name={`persons.${index}.lastName`}
                                                control={control}
                                                label="Sukunimi"
                                                required={true}
                                                multiline={false}
                                            />
                                            
                                        </Stack>

                                        <Controller
                                            name={`persons.${index}.dob`}
                                            key={`${field.id}-dob`}
                                            control={control}
                                            render={({
                                                field: { onChange, value, onBlur },
                                                fieldState: { error, isTouched },
                                                formState,
                                            }) => {



                                                return <DatePicker
                                                    sx={{ width: {sm: "100%", md: "20%"} }}

                                                    label="Syntymäaika"
                                                    slotProps={{
                                                        textField: {
                                                            placeholder: 'PP.KK.VVVV', onBlur: (event) => {
                                                                //formik.setFieldTouched('readingDate', true);

                                                            },
                                                            helperText: (isTouched && error) ? error.message : null,
                                                            error: isTouched && !!error
                                                        },

                                                    }}
                                                    value={value}
                                                    onChange={onChange}

                                                />
                                            }}
                                        />
                                       

                                    </Stack>
                                </Paper>
                            )


                        })}



                    </Grid>
                    <Grid xs={12}>
                        <Button
                            sx={{ marginLeft: "auto" }}

                            color="info"
                            variant="contained"
                            type="submit"
                            onClick={(event) => {
                                append({
                                    id: null,
                                    firstName: "",
                                    lastName: "",
                                    dob: null
                                })
                            }} >
                            Lisää henkilö
                        </Button>
                    </Grid>
                    <Grid xs={12}>
                      
                            <PublicFormInputRadioGroup
                                control={control}
                                label="Onko joku muuttajista taloyhtiön osakas?"
                                name="isMoverShareholder"
                                required={true}
                                key="isowner-radiobutton-group"
                                options={
                                    [
                                        { label: "Kyllä", value: 1 },
                                        { label: "Ei", value: 0 },
                                   ]
                                }
                            />

                    </Grid>
                    {isShareHolder == 1 && <Grid xs={12}>

                        <PublicFormInputRadioGroup
                            control={control}
                            label="Onko asunto asunnosta tehty kauppa?"
                            name="isPropertySold"
                            required={true}
                            key="isPropertySold-radiobutton-group"
                            options={
                                [
                                    { label: "Kyllä", value: 1 },
                                    { label: "Ei", value: 0 },
                                ]
                            }
                        />

                    </Grid>}
                    {isPropertySold == 1 && <Grid xs={12}>

                        <PublicFormInputRadioGroup
                            control={control}
                            label="Onko kauppakirja toimitettu isännöintiin?"
                            name="contractDeliveredEstateManager"
                            required={true}
                            key="isowner-radiobutton-group"
                            options={
                                [
                                    { label: "Kyllä", value: 1 },
                                    { label: "Ei", value: 0 },
                                ]
                            }
                        />

                    </Grid>}

                    <Grid xs={12}>
                        <PublicFormInputCheckbox
                            control={control}
                            name="acceptTerms"
                            required={true}
                            label={<span>
                                <span>Vakuutan tiedot oikeiksi ja hyväksyn </span>
                                <Link href={'https://uniko.fi/tietosuojaseloste'} target="_blank">tietosuoja- ja rekisteriselosteen</Link>

                            </span>}
                        />
                    </Grid>
                    <Grid xs={12}>
                        {props.sendError && <SendErrorNotification message={props.message} />}

                    </Grid>
                   

                    <FormNavigationButtons
                        stepState={props.stepState}
                        handleBack={props.handleBack}
                        handleNext={props.handleNext}
                        isFormValid={props.isFormValid}

                    />
                </Grid>
            </Card>

        </>)

}


interface IPersonInfo {
    control: any;

}

function PersonInfo(props: IPersonInfo) {

    const { control } = props;

    return (<>
        <Grid xs={12}>
            <PublicFormInputText
                key={`additional-info-textinput`}
                name="additionalInfo"
                control={control}
                label="Lisätietoja"
                required={false}
                multiline={true}
            />

            <PublicFormInputText
                key={`additional-info-textinput`}
                name="additionalInfo"
                control={control}
                label="Lisätietoja"
                required={false}
                multiline={true}
            />


        </Grid>
    </>);


}






interface IMeterReadingInfo {
    control: Control<IChangeAddress, any>;
    stepState: IStepState;
    handleBack: () => void;
    handleNext: () => void;
    isFormValid: boolean;

}
export function MeterReadingInfo(props: IMeterReadingInfo) {

    const { control } = props;

    return (
        <>
            <Card
                id="card-1"
                key='card-1'
                sx={{
                    maxWidth: "100% !important",
                    p: 3
                }} >

                <Grid
                    container
                    spacing={3}
                    key='meterreading-grid-container'
                >
                    <Grid xs={12}>
                        <PublicFormInputRadioGroup
                            control={control}
                            label="Saako huoltoyhtiö käydä huoneistossa yleisavainta käyttäen lukemassa mittarit muuton yhteydessä? *"
                            name="meterReadingAllowed"
                            required={true}
                            key="meterreading-allowed-radiobutton-group"
                            options={
                                [
                                    { label: "Kyllä", value: MeterReadingAllowed.Yes },
                                    { label: "Ei, huoneistossa on lemmikkejä", value: MeterReadingAllowed.No },
                                    { label: "Ilmoitan itse lukeman", value: MeterReadingAllowed.WillReportReadigs }
                                ]
                            }
                        />
                    </Grid>
                    <Grid xs={12}>
                        <PublicFormInputText
                            key={`additional-info-textinput`}
                            name="additionalInfo"
                            control={control}
                            label="Lisätietoja"
                            required={false}
                            multiline={true}
                        />
                    </Grid>


                    <FormNavigationButtons
                        stepState={props.stepState}
                        handleBack={props.handleBack}
                        handleNext={props.handleNext}
                        isFormValid={props.isFormValid}

                    />
                </Grid>
            </Card>

        </>)

}







interface IChangeAddressInformation {
    control: Control<IChangeAddress, any>;
    setValue: any;
    stepState: IStepState;
    handleBack: () => void;
    handleNext: () => void;
    isFormValid: boolean;

    addressQuery: UseQueryResult<IAddressResponse, Error>;
    debouncedSetter: (keyword: string) => void;

    setInputValue: Dispatch<SetStateAction<string>>;
    inputValue: string;

}
export function ChangeAddressInformation(props: IChangeAddressInformation) {

    const { control, addressQuery, debouncedSetter, setValue } = props;

    const typeOfNotification: ChangeAddressNotificationType | null = useWatch({ control, name: 'typeOfNotification' });

    const title = typeOfNotification == ChangeAddressNotificationType.In ? `Mihin muutat?` : `Mistä muutat?`;
    const addressTitle = typeOfNotification == ChangeAddressNotificationType.In ? `Ilmoita vanha osoite` : `Ilmoita uusi osoite`;
    const addressHelperText = typeOfNotification == ChangeAddressNotificationType.Out ? 'Syötä kenttään se osoite, johon olet muuttamassa (esim. Eerikinkatu 4 B as. 8, 20100 Turku)' : 'Syötä kenttään se osoite, josta olet muuttamassa (esim. Eerikinkatu 4 B as. 8, 20100 Turku)'
    return (
        <>
            <Card
                id="card-1"
                key='card-1'
                sx={{
                    maxWidth: "100% !important",
                    p: 3
                }} >

                <Grid
                    container
                    spacing={3}
                    key='main-grid-container'
                >
                    <Typography variant="h5" gutterBottom>
                        {''}
                    </Typography>

                    <Grid xs={12}>
                        <Box sx={{ m: 1, pl: 1 }}>
                            <Controller
                                name="changeOfAddressDate"
                                key={`${"changeOfAddressDate"}-controller`}
                                control={control}
                                render={({
                                    field: { onChange, value, onBlur },
                                    fieldState: { error, isTouched },
                                    formState,
                                }) => {
                                    return <DatePicker
                                        sx={{ width: "60%" }}

                                        label="Muuttopäivämäärä"
                                        slotProps={{
                                            textField: {
                                                placeholder: 'PP.KK.VVVV', onBlur: (event) => {
                                                    //formik.setFieldTouched('readingDate', true);

                                                },
                                                helperText: (isTouched && error) ? error.message : null,
                                                error: isTouched && !!error
                                            },

                                        }}
                                        value={value}
                                        onChange={onChange}

                                    />
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                    <AddressCollector
                        control={control}
                        setValue={setValue}
                        title={"Mistä muutat?"}
                        namepostalcode="fromAddress.postalcode"
                        namecity="fromAddress.city"
                        nameaddressDDLValue="fromAddress.addressDDLValue"
                        namestaircase="fromAddress.staircase"
                        nameapartment="fromAddress.apartment"
                    />
                    </Grid>
                    <Grid xs={12}>
                    <AddressCollector
                        control={control}
                        setValue={setValue}
                        title={"Minne muutat?"}
                        namepostalcode="toAddress.postalcode"
                        namecity="toAddress.city"
                        nameaddressDDLValue="toAddress.addressDDLValue"
                        namestaircase="toAddress.staircase"
                        nameapartment="toAddress.apartment"
                    />
                    </Grid>

                    <FormNavigationButtons
                        stepState={props.stepState}
                        handleBack={props.handleBack}
                        handleNext={props.handleNext}
                        isFormValid={props.isFormValid}

                    />


                </Grid>
            </Card>
        </>)

}




interface IPersonInformation {
    control: Control<IChangeAddress, any>;
    stepState: IStepState;
    handleBack: () => void;
    handleNext: () => void;
    isFormValid: boolean;

}
export function PersonInformation(props: IPersonInformation) {

    const { control } = props;

    const selectedRole: RoleType | null = useWatch({ control, name: 'role' });

    return (
        <>
            <Card
                id="card-1"
                key='card-1'
                sx={{
                    maxWidth: "100% !important",
                    p: 3
                }} >

                <Grid
                    container
                    spacing={3}
                    key='main-grid-container'
                >
                    <Grid xs={12}>
                        <PublicFormInputRadioGroup
                            control={control}
                            required={true}
                            label="Ilmoituksen tyyppi *"
                            name="typeOfNotification"
                            key="typeof-notification-radiobutton-group"
                            options={
                                [
                                    { label: "Sisäänmuutto", value: ChangeAddressNotificationType.In },
                                    { label: "Poismuutto", value: ChangeAddressNotificationType.Out },

                                ]
                            }
                        />
                    </Grid>

                    <Grid xs={12}>
                        <PublicFormInputText
                            key={`firstname-input`}
                            name="firstName"
                            control={control}
                            label="Etunimi"
                            required={true}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <PublicFormInputText
                            name="lastName"
                            control={control}
                            label="Sukunimi"
                            required={true}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <PublicFormInputText
                            name="phoneNumber"
                            control={control}
                            label="Puhelinnumero"
                            required={true}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <PublicFormInputText
                            name="email"
                            control={control}
                            label="Sähköposti"
                            required={true}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <PublicFormInputDropdown
                            name="role"
                            required={true}
                            control={control}
                            label="Ilmoittaja on"
                            options={[
                                { value: RoleType.Resident, label: "Osakas" },
                                { value: RoleType.Tenant, label: "Vuokralainen" },
                                { value: RoleType.Other, label: "Muu" },

                            ]}
                        />
                    </Grid>
                    {selectedRole == RoleType.Other && <Grid xs={12}>

                        <PublicFormInputText
                            name="otherName"
                            control={control}
                            label="Muu, mikä?"
                            required={true}
                        />
                    </Grid>}


                    <FormNavigationButtons
                        stepState={props.stepState}
                        handleBack={props.handleBack}
                        handleNext={props.handleNext}
                        isFormValid={props.isFormValid}

                    />

                </Grid>
            </Card>
        </>)

}



interface IFormNavigationButtons {
    stepState: any;
    handleBack: any;
    handleNext: any;
    /* nextActive: boolean;*/
    //flowState: any;
    isFormValid: boolean;

}

function FormNavigationButtons(props: IFormNavigationButtons) {

    const { stepState, handleBack, handleNext, isFormValid } = props;

    return (
        <Grid container xs={12} justifyContent="flex-end" sx={{ p: 2 }}>

            {stepState.activeStep > 0 && <Button
                color="inherit"
                variant="outlined"
                disabled={stepState.activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
            >
                Palaa
            </Button>}



            {stepState.activeStep === steps.length - 1 ? <></> : <Button sx={{ marginLeft: "auto" }} disabled={!props.isFormValid} onClick={handleNext} variant="contained" color={!props.isFormValid ? "inherit" : "success"}>
                Seuraava
            </Button>}


            {stepState.activeStep == steps.length - 1 ? <Button
                sx={{ marginLeft: "auto" }}
                disabled={!props.isFormValid}
                color="success"
                variant="contained"
                type="submit"
                onClick={(event) => {
                    //props.setAllFieldsTouched(props.formik);
                }} >
                Lähetä ilmoitus
            </Button> : <></>}
        </Grid>
    );
}


interface IValueSendMessageProps {
    backToStart: () => void;
    message: stringOrNull;
}

function ValueSendMessage(props: IValueSendMessageProps) {
    return (<>
        <Paper sx={{ p: 2, position: "relative", height: "100%", minHeight: 100 }} elevation={3}>

            <Grid container spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid xs={1}>
                    <CheckCircleOutlineIcon fontSize="large" color="success" />
                </Grid>

                <Grid xs={11}>
                    <Typography sx={{ ml: 1 }}>
                        {props.message}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid xs={1}>

                </Grid>

                <Grid xs={11}>
                    <Button
                        color="inherit"
                        variant="contained"
                        onClick={(event) => { props.backToStart() }}
                        sx={{ mt: 3 }}
                    >
                        Lisää uusi muuttoilmoitus
                    </Button>
                </Grid>
            </Grid>




        </Paper>
    </>)
}


