import * as React from "react";
import { MeterReadingForm } from "./components/MeterReadingForm";

export function App() {

    return (
        <>
            <div id="main" className="main">
               
                    <div>
                        <><MeterReadingForm /></>
                    </div>
                    
                
            </div>
        </>
    )
}