import { Controller } from "react-hook-form";
import React from "react";
import { Checkbox, FormControlLabel, Link } from "@mui/material";

export interface IPublicFormInputCheckbox {
    name: string;
    control: any;
    label: JSX.Element;
    disabled?: boolean;
    required?: boolean;
}

export function PublicFormInputCheckbox({ name, control, label, disabled, required = false }: IPublicFormInputCheckbox) {

    const isDisabled = disabled ?? false;

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, onBlur },
                fieldState: { error },
                formState,
            }) => (
                <FormControlLabel
                    key={`${name}-label`}
                    required={required}
                    control={<Checkbox
                        key={`${name}-checkbox`}
                        disabled={isDisabled}
                        checked={value}
                        name={name}
                        onChange={onChange} />}
                    label={label}
                />)}
    />)

}