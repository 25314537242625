import "./meterreading.less";
import * as React from "react";
import { createRoot } from 'react-dom/client';
import { App } from "./App";
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/fi';
import { LicenseInfo } from '@mui/x-license-pro';

export function MeterSubmitIndex() {
    return <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fi">
        <App key="meter-reading-app" />
    </LocalizationProvider>

}

   



