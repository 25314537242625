import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps, SelectInputProps } from "./PublicFormInputProps";




export const PublicFormInputDropdown: React.FC<SelectInputProps> = ({
    name,
    control,
    label,
    required,
    options,
    handleChangeWithSave
}) => {
    const generateSingleOptions = () => {
        return options.map((option: any) => {
            return (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            );
        });
    };

    const onChangeHandler = (event: any, originalOnChange: any) => {

        if (handleChangeWithSave) {
            handleChangeWithSave(event);
        }

        originalOnChange(event);


    }


    return (
        <FormControl size={"medium"}>
            <InputLabel required={required ?? false} id={`${name}-label`}>{label}</InputLabel>
            <Controller
                render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <Select
                        sx={{ minWidth: 230 }}
                        label={label}
                        onBlur={onBlur}
                        error={!!error}
                       
                        required={required ?? false}
                        labelId={`${name}-label`}
                        onChange={(event) => {
                        onChangeHandler(event, onChange);
                    }} size="medium" value={value ?? ''}>
                        {generateSingleOptions()}
                    </Select>
                )}
                control={control}
                name={name}

            />
        </FormControl>
    );
};